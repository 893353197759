<!-- 28-1-8 popup -->
<template>
  <s-dialog to="stds-rating-unavailable-teen-dialog" size="md" :open="true">
    <s-dialog-overlay />
    <s-dialog-panel>
      <s-dialog-content>
        <div class="pt-48 pb-24">
          <p class="text-xl leading-lg font-bold text-on-surface-elevation-1 text-center">
            <safe-html
              :html="
                $t('studio.prj_prod_mngmt.rating_build_review.self_review.post_review_guide2_1')
              "
            />
          </p>
          <p class="mt-8 text-center text-lg font-regular text-on-surface-elevation-3 leading-lg">
            <safe-html
              tag="div"
              :html="
                $t('studio.prj_prod_mngmt.rating_build_review.self_review.post_review_guide2_2')
              "
            />
            <br />
            <safe-html
              tag="div"
              :html="
                $t('studio.prj_prod_mngmt.rating_build_review.self_review.post_review_guide2_3')
              "
            />
          </p>
        </div>
      </s-dialog-content>
      <s-dialog-footer>
        <s-button class="sm:min-w-264" @click="onClose">
          {{ $t('studio.common.popup_case_cf_btn') }}
        </s-button>
      </s-dialog-footer>
    </s-dialog-panel>
  </s-dialog>

  <s-portal-target name="stds-rating-unavailable-teen-dialog" />
</template>
<script setup lang="ts">
import safeHtml from '@/components/common/safe-html.vue';

const emits = defineEmits<{
  close: [v: boolean];
}>();

const onClose = () => {
  emits('close', false);
};
</script>
