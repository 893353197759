<template>
  <div class="flex justify-center gap-20 max-w-[120rem] mx-auto mb-32">
    <div class="min-w-[100rem] max-w-full">
      <div class="flex justify-center gap-8">
        <rating-survey-step-item
          v-for="(survey, index) in surveyList"
          :key="survey.id"
          :stepTitle="`${survey.no} ${survey.category}`"
          :status="survey.status"
          :isCurrent="activeSurveyCategoryIndex === index"
          @click="onClickCategory(index)"
        />
      </div>
      <rating-survey-progress-bar class="mt-20" :progressValue="progressValue" />
    </div>
  </div>
</template>

<script setup lang="ts">
import RatingSurveyProgressBar from '@/components/rating/rating-survey-progress-bar.vue';
import RatingSurveyStepItem from '@/components/rating/rating-survey-step-item.vue';
import type { DraftQuestionnaireReviewQuestionnaire } from '@/types/rating/rating.response.type';

defineProps<{
  progressValue: number;
  surveyList?: DraftQuestionnaireReviewQuestionnaire[];
  activeSurveyCategoryIndex: number;
}>();

const emit = defineEmits<{
  onChangeCategory: [id: number];
}>();

const onClickCategory = (index: number) => {
  emit('onChangeCategory', index);
};
</script>
