<!-- eslint-disable vue/html-indent -->
<template>
  <s-dialog to="studio-rating-survey-dialog" size="lg" :open="true">
    <s-dialog-panel class="!w-full !h-full min-w-[128rem] !max-h-[initial] !rounded-none">
      <div class="flex h-full w-full flex-col bg-surface-elevation-1">
        <!-- Header -->
        <rating-survey-header
          :headerTitle="headerTitle"
          :currentActiveTab="currentActiveTab!"
          :type="type"
          :status="surveyStatus"
          :hideSurveyTab="hideSurveyTab"
          :mode="mode"
          :hideSaveDraftButton="isHaveBasicInformationChange"
          @changeTab="onClickChangeTab"
          @close="onClickCloseDialog"
          @onSaveDraft="onClickSaveDraft"
          @onRequestReview="onClickRequestReview"
        />
        <div class="relative flex flex-1">
          <div
            v-if="!isLoading"
            class="studio-scrollbar-4 !overflow-y-auto absolute inset-0 bg-background-variant-1 pt-40 pb-[12rem]"
          >
            <rating-survey-form
              v-show="currentActiveTab === RatingSurveyTab.SURVEY"
              :progressValue="progressValue"
              :surveyList="surveyData?.questionnaire"
              :activeSurveyCategoryIndex="activeSurveyCategoryIndex"
              @onChangeCategory="onChangeCategory"
            />
            <!-- Page Tab List -->
            <div
              class="flex gap-24 w-[120rem] mx-auto"
              :class="{ 'mt-20': currentActiveTab !== RatingSurveyTab.SURVEY }"
            >
              <div class="flex-1 min-w-0">
                <template v-if="type === SurveyDialogType.OBJECTION">
                  <div v-show="currentActiveTab === RatingSurveyTab.OBJECTION">
                    <rating-survey-dialog-objection
                      :currentRating="(currentRating as RequestRating | undefined)"
                      :reason="values.objection.reason"
                      :mode="mode"
                      @onNext="onNextObjection"
                    />
                  </div>
                </template>
                <template v-if="type === SurveyDialogType.REVISION">
                  <div v-show="currentActiveTab === RatingSurveyTab.REVISION">
                    <rating-survey-dialog-revision
                      :mode="mode"
                      :buildInfo="buildInfo"
                      :revisionProductSettingChanged="revisionProductSettingChanged"
                      :ratingProduct="ratingProduct"
                      :currentRating="currentRating"
                      :buildItems="buildItems"
                      @onNext="onNextRevision"
                    />
                  </div>
                </template>
                <div v-show="currentActiveTab === RatingSurveyTab.SURVEY">
                  <rating-survey-dialog-questionnaire
                    :surveyContent="currentQuestionContent!"
                    :no="surveyData?.questionnaire[activeSurveyCategoryIndex].no!"
                    :category="surveyData?.questionnaire[activeSurveyCategoryIndex].category!"
                    :categoryContentsLength="surveyData?.questionnaire[activeSurveyCategoryIndex].contents.length!"
                    :activeQuestionIndex="activeQuestionIndex"
                    :disabled="disabledSurveyTab"
                    :surveyStatus="surveyStatus"
                    :activeSurveyCategoryIndex="activeSurveyCategoryIndex"
                    :surveyQuestionnaireLength="surveyData?.questionnaire.length!"
                    :firstQuestionNo="firstQuestionNo"
                    :lastQuestionNo="lastQuestionNo"
                    :isSubmittedReview="isSubmittedReview"
                    @onClickChoice="onClickChoice"
                    @onPrevious="onPrevious"
                    @onNext="onNext"
                    @onClickChangeTab="onClickChangeTab"
                  />
                </div>
                <div v-show="currentActiveTab === RatingSurveyTab.ATTACH">
                  <rating-survey-dialog-attach
                    :mode="mode"
                    :productDescription="values.attach.productDescription"
                    :productControlDescription="values.attach.productControlDescription"
                  />
                </div>
              </div>
              <component :is="surveyExplainComponent" />
            </div>
          </div>
        </div>
      </div>
    </s-dialog-panel>
  </s-dialog>

  <s-portal-target name="studio-rating-survey-dialog" />
</template>
<script setup lang="ts">
import { useHead } from '@unhead/vue';
import { isEmpty } from 'lodash';
import { storeToRefs } from 'pinia';
import { useForm } from 'vee-validate';
import { computed, defineAsyncComponent, ref, shallowRef } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { fetchProductBuildUploadCompletedApi } from '@/apis/build.api';
import {
  fetchDraftQuestionnaireReviewApi,
  fetchQuestionnaireContentApi,
  fetchQuestionnaireReviewApi,
  fetchRatingsProductsApi,
  postProductRatingAnswerApi,
  postRatingProductsVerifyRequestApi,
  postRatingsGlobalProductsApi,
  postRatingsRevisionProductsApi,
  saveDraftQuestionnaireReviewApi,
  submitQuestionnaireReviewApi
} from '@/apis/rating.api';
import RatingSurveyNotifyDialogConfirmRequestReview from '@/components/rating/dialog/request-review-dialog.vue';
import RatingSurveyNotifyDialogUnavailableTeen from '@/components/rating/dialog/unavailable-teen-dialog.vue';
import RatingSurveyDialogAttach from '@/components/rating/rating-survey-dialog-attach.vue';
import RatingSurveyDialogObjection from '@/components/rating/rating-survey-dialog-objection.vue';
import RatingSurveyDialogQuestionnaire from '@/components/rating/rating-survey-dialog-questionnaire.vue';
import RatingSurveyDialogRevision from '@/components/rating/rating-survey-dialog-revision.vue';
import RatingSurveyForm from '@/components/rating/rating-survey-form.vue';
import RatingSurveyHeader from '@/components/rating/rating-survey-header.vue';
import { useApp } from '@/composables/useApp';
import { showAlert, showConfirm, showDialog } from '@/composables/useDialog';
import { PAGE_SIZE_BUILD } from '@/constants/build.const';
import { DEFAULT_LANG_CODE, SORT_DIRECTION } from '@/constants/common.const';
import { COMMON_ERROR_MESSAGE_KEY, STATUS_CODE } from '@/constants/error.const';
import { SURVEY_NEXT } from '@/constants/rating.const';
import { PRODUCT_RATING_URL } from '@/constants/url.const';
import { Confirmation } from '@/enums/common.enum';
import {
  ChoiceType,
  ObjectionCode,
  RatingAge,
  RatingPageMode,
  RatingSurveyStatus,
  RatingSurveyTab,
  RevisionType,
  SurveyDialogType
} from '@/enums/rating.enum';
import useProductStore from '@/stores/product.store';
import type { Build, FetchProductBuildParams } from '@/types/build/build.type';
import type { ErrorResponse } from '@/types/common/common.type';
import type { FormOptionGroup } from '@/types/common/form.type';
import type {
  Iarc,
  PostProductRatingAnswerRequest,
  PostRatingRevisionProductRequest,
  SaveQuestionnaireReviewRequest,
  StartSurveyRequest,
  SubmitQuestionnaireReviewRequest
} from '@/types/rating/rating.request.type';
import type {
  DraftQuestionnaireReviewContents,
  DraftQuestionnaireReviewQuestionnaire,
  DraftQuestionnaireReviewQuestionnaireContent,
  DraftQuestionnaireReviewQuestionnaireContentChoice,
  DraftQuestionnaireReviewQuestionnaireTypeQuestion,
  DraftQuestionnaireReviewSurvey,
  GlobalRatings,
  QuestionnaireReviewResponse,
  RatingProductResponse,
  ReleasedRatings,
  RequestRating
} from '@/types/rating/rating.response.type';
import type {
  RatingAttachForm,
  RatingObjectionForm,
  RatingRevisionForm,
  RevisionProductSettingChanged
} from '@/types/rating/rating.type';
import { redirectTo, showCommonErrorDialog } from '@/utils/common.util';
import { getAgeRatingText, getCorrectRating } from '@/utils/rating.util';

const props = withDefaults(
  defineProps<{
    type: SurveyDialogType;
    mode?: RatingPageMode;
    revisionProductSettingChanged?: RevisionProductSettingChanged;
    revisionTypes?: string[];
    buildId?: string;
    originNation: string;
    productSummary: string;
  }>(),
  {
    mode: RatingPageMode.EDIT,
    revisionProductSettingChanged: () => ({
      isChangedProductName: false,
      isChangedDeveloper: false,
      isChangedGenre: false,
      productNameKo: '',
      productNameEn: '',
      developer: '',
      genreTagNos: undefined,
      defaultGenreTag: undefined,
      defaultGenreTagName: undefined,
      infos: []
    }),
    revisionTypes: () => [],
    buildId: '',
    originNation: '',
    productSummary: ''
  }
);

const emit = defineEmits<{
  close: [v: boolean];
}>();

useHead({
  bodyAttrs: {
    class: 'overflow-hidden'
  }
});

const { checkProductPermission } = useApp();

const route = useRoute();
const { t } = useI18n();
const productStore = useProductStore();

const { isReleased, product } = storeToRefs(productStore);

const projectId = route.params.projectId as string;
const gameId = product.value?.gameId || '';

const buildItems = ref<FormOptionGroup<string | number>[]>([]);
const isLoading = ref<boolean>(true);
const productId = route.params.productId as string;
const groupId = route.params.groupId as string;
const buildInfo = ref<StartSurveyRequest | undefined>();

const { type, mode, revisionProductSettingChanged, revisionTypes } = props;
const disabledSurveyTab = ref<boolean>(mode === RatingPageMode.DETAIL);
const ratingProduct = ref<RatingProductResponse>();
const currentRating = ref<RequestRating | ReleasedRatings>();
const activeSurveyCategoryIndex = ref<number>(0); // Parent
const activeQuestionIndex = ref<number>(0); // Child
const serviceUniqueKey = ref<number>();
const surveyStatus = ref<RatingSurveyStatus>(RatingSurveyStatus.START);
const surveyData = ref<DraftQuestionnaireReviewSurvey>();
const answers = ref<number[]>([]);
const endQuestion = ref<DraftQuestionnaireReviewQuestionnaireTypeQuestion>();
const firstQuestionNo = ref<string>('');
const lastQuestionNo = ref<string>('');
const currentActiveTab = ref<RatingSurveyTab>();
const isSubmittedReview = ref<boolean>(false);

const originGlobalRatings = ref<Iarc[]>([]);

const buildSearchParams = ref<FetchProductBuildParams>({
  teamId: groupId,
  projectId,
  gameId,
  sort: 'CREATED_AT',
  direction: SORT_DIRECTION.DESC,
  searchValue: '',
  page: 1,
  size: PAGE_SIZE_BUILD
});

const isHaveBasicInformationChange =
  revisionProductSettingChanged.isChangedProductName ||
  revisionProductSettingChanged.isChangedDeveloper ||
  revisionProductSettingChanged.isChangedGenre;

const { values, setFieldValue, validate } = useForm<{
  objection: RatingObjectionForm;
  revision: RatingRevisionForm;
  attach: RatingAttachForm;
}>({
  initialValues: {
    objection: {
      adjustRating: '',
      usePreviousSurveyAnswer: false,
      contentInfos: [],
      reason: ''
    },
    revision: {
      usePreviousSurveyAnswer: false,
      revisionContent: '',
      revisionTypes,
      buildItem: props.buildId,
      globalRatings: [],
      isChangeIntlRating: false
    },
    attach: {
      productDescription: t(
        'studio.prj_prod_mngmt.rating_build_review.self_review.info_game_descr_sample'
      ),
      productControlDescription: '',
      referenceDownloadLinks: ['', ''], // Default 2 links
      fileUrls: [],
      fileUrl: ''
    }
  }
});

const headerTitle = computed<string>(() => {
  switch (type) {
    case SurveyDialogType.OBJECTION:
      return t('studio.prj_prod_mngmt.rating_build_review.self_review.appeal_req_cf_btn');
    case SurveyDialogType.REVISION:
      return t('studio.prj_prod_mngmt.rating_build_review.self_review.report_change_btn');
    case SurveyDialogType.REGISTER:
    default:
      return t('studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.title');
  }
});

const currentCategory = computed(() => {
  return surveyData.value?.questionnaire[activeSurveyCategoryIndex.value];
});

const currentQuestionContent = computed(() => {
  return currentCategory.value?.contents[activeQuestionIndex.value];
});

const surveyExplainComponent = computed(() => {
  if (currentActiveTab.value === RatingSurveyTab.SURVEY) {
    switch (currentQuestionContent.value?.no) {
      case '0.2.':
      case '0.3.':
        return defineAsyncComponent(
          () => import('@/components/rating/survey-explain/survey-explain-pre2.vue')
        );
      case '1.1.':
        return defineAsyncComponent(
          () => import('@/components/rating/survey-explain/survey-explain-violence.vue')
        );
      case '1.2.':
      case '1.3.':
        return defineAsyncComponent(
          () => import('@/components/rating/survey-explain/survey-explain-violence3.vue')
        );
      case '1.4.':
        return defineAsyncComponent(
          () => import('@/components/rating/survey-explain/survey-explain-violence4.vue')
        );
      case '1.5.':
        return defineAsyncComponent(
          () => import('@/components/rating/survey-explain/survey-explain-violence5.vue')
        );
      case '2.1.':
        return defineAsyncComponent(
          () => import('@/components/rating/survey-explain/survey-explain-sexuality.vue')
        );
      case '2.2.':
      case '2.3.':
        return defineAsyncComponent(
          () => import('@/components/rating/survey-explain/survey-explain-sexuality2.vue')
        );
      case '3.1.':
        return defineAsyncComponent(
          () => import('@/components/rating/survey-explain/survey-explain-gambling.vue')
        );
      case '3.2.':
        return defineAsyncComponent(
          () => import('@/components/rating/survey-explain/survey-explain-gambling2.vue')
        );
      case '3.3.':
        return defineAsyncComponent(
          () => import('@/components/rating/survey-explain/survey-explain-gambling3.vue')
        );
      case '4.1.':
      case '4.2.':
        return defineAsyncComponent(
          () => import('@/components/rating/survey-explain/survey-explain-horror.vue')
        );
      case '5.1.':
        return defineAsyncComponent(
          () => import('@/components/rating/survey-explain/survey-explain-language.vue')
        );
      case '6.1.':
        return defineAsyncComponent(
          () => import('@/components/rating/survey-explain/survey-explain-drugs.vue')
        );
      case '6.2.':
        return defineAsyncComponent(
          () => import('@/components/rating/survey-explain/survey-explain-drugs2.vue')
        );
      case '7.1.':
        return defineAsyncComponent(
          () => import('@/components/rating/survey-explain/survey-explain-crime.vue')
        );
      case '0.1.':
      default:
        return defineAsyncComponent(
          () => import('@/components/rating/survey-explain/survey-explain-pre.vue')
        );
    }
  } else if (currentActiveTab.value === RatingSurveyTab.ATTACH) {
    return defineAsyncComponent(
      () => import('@/components/rating/survey-explain/survey-explain-attach.vue')
    );
  } else if (currentActiveTab.value === RatingSurveyTab.REVISION) {
    return defineAsyncComponent(
      () => import('@/components/rating/survey-explain/survey-explain-revision.vue')
    );
  } else if (currentActiveTab.value === RatingSurveyTab.OBJECTION) {
    return defineAsyncComponent(
      () => import('@/components/rating/survey-explain/survey-explain-objection.vue')
    );
  }
  return '';
});

const totalQuestionCount = ref<number>(0);
const getTotalQuestionCount = () => {
  let count = 0;
  if (surveyData.value) {
    for (const category of surveyData.value.questionnaire) {
      for (const group of category.contents) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (const question of group.contents) {
          count++;
        }
      }
    }
  }
  return count;
};

const getCurrentIdFromQuestion = (question: DraftQuestionnaireReviewQuestionnaireTypeQuestion) => {
  return Array.isArray(question.answers) ? question.answers[0] : question.answers;
};

const gettNextIdFromQuestion = (
  question: DraftQuestionnaireReviewQuestionnaireTypeQuestion,
  currentId: number
) => {
  return question.choice.find((choice: DraftQuestionnaireReviewQuestionnaireContentChoice) => {
    if (choice.id === currentId) {
      return true;
    }
    return false;
  })?.next;
};

const totalAnswerCount = ref<number>(0);
const setTotalAnswerCountAndStatus = () => {
  let count = 0;
  let isAnswered = false;
  if (surveyData.value) {
    for (const category of surveyData.value.questionnaire) {
      category.status = RatingSurveyStatus.START;
    }

    for (const category of surveyData.value.questionnaire) {
      category.status = RatingSurveyStatus.START;
      for (const group of category.contents) {
        for (const question of group.contents) {
          count++;
          if (question.answers) {
            isAnswered = true;
            surveyStatus.value = RatingSurveyStatus.INCOMPLETE;
            category.status = RatingSurveyStatus.INCOMPLETE;

            const currentId = getCurrentIdFromQuestion(question);
            const nextId = gettNextIdFromQuestion(question, currentId!);
            if (nextId) {
              const nextCategory = getCategory(Number(nextId));
              if (nextCategory?.category !== category.category) {
                category.status = RatingSurveyStatus.COMPLETE;
              }

              const nextQuestion = getQuestion(Number(nextId));
              if (nextQuestion) {
                nextQuestion.hide = false;
                if (Array.isArray(nextQuestion.answers)) {
                  if (nextQuestion.answers.length === 0) {
                    totalAnswerCount.value = count;
                    return;
                  }
                } else if (!nextQuestion?.answers) {
                  totalAnswerCount.value = count;
                  return;
                }
              }

              if (nextId === SURVEY_NEXT.END) {
                setEndSurveyQuestion(question);
                return;
              }
            }
          }
        }
      }
    }
  }
  if (isAnswered) {
    totalAnswerCount.value = count;
  } else {
    totalAnswerCount.value = 0;
  }
};

const progressValue = computed<number>(() => {
  if (totalQuestionCount.value && totalAnswerCount.value) {
    return Math.floor((totalAnswerCount.value / totalQuestionCount.value) * 100);
  }
  return 0;
});

const hideSurveyTab = computed<boolean>(() => {
  if (type === SurveyDialogType.OBJECTION) {
    return values.objection.usePreviousSurveyAnswer;
  }

  if (type === SurveyDialogType.REVISION) {
    return values.revision.usePreviousSurveyAnswer;
  }

  return false;
});

const onNextObjection = () => {
  if (hideSurveyTab.value) {
    currentActiveTab.value = RatingSurveyTab.ATTACH;
    return;
  }

  currentActiveTab.value = RatingSurveyTab.SURVEY;
};

const onNextRevision = () => {
  if (hideSurveyTab.value) {
    currentActiveTab.value = RatingSurveyTab.ATTACH;
    return;
  }

  currentActiveTab.value = RatingSurveyTab.SURVEY;
};

const onClickSaveDraft = async () => {
  if (!(await checkProductPermission())) {
    return;
  }

  const saveQuestionnaireReviewRequest: SaveQuestionnaireReviewRequest = {
    language: surveyData.value?.language ?? DEFAULT_LANG_CODE,
    buildId:
      (type === SurveyDialogType.REVISION ? values.revision.buildItem : buildInfo.value?.buildId) ??
      '',
    version: surveyData.value?.version || '',
    results: getAnswers(),
    originNation: buildInfo.value?.originNation || '',
    productSummary: buildInfo.value?.productSummary || '',
    productDescription: values.attach.productDescription,
    productControlDescription: values.attach.productControlDescription,
    fileUrls: values.attach.fileUrls,
    referenceDownloadLinks: values.attach.referenceDownloadLinks,
    revisionTypes: values.revision.revisionTypes,
    revisionContent: values.revision.revisionContent
  };

  if (type === SurveyDialogType.REVISION && values.revision.globalRatings.length > 0) {
    const postGlobalRatingRequest = {
      directYn: (currentRating.value as ReleasedRatings).directYn,
      globalRatings: values.revision.globalRatings
    };
    await Promise.all([
      saveDraftQuestionnaireReviewApi(productId, saveQuestionnaireReviewRequest),
      postRatingsGlobalProductsApi(productId, postGlobalRatingRequest)
    ]);
  } else {
    await saveDraftQuestionnaireReviewApi(productId, saveQuestionnaireReviewRequest);
  }

  showAlert({
    content: t('studio.common.popup_case_d_complete_save'),
    confirmLabel: t('studio.common.popup_case_cf_btn')
  });
};

const onClickRequestReview = async () => {
  if (!(await checkProductPermission())) {
    return;
  }

  isSubmittedReview.value = true;
  if (type === SurveyDialogType.OBJECTION) {
    onSaveAppeal();
    return;
  }

  if (type === SurveyDialogType.REVISION) {
    onSaveRevision();
    return;
  }

  if (type === SurveyDialogType.REGISTER) {
    onSaveRegister();
  }
};

const checkGroupForm = (errors: Partial<Record<string, string>>, group: string) => {
  return Object.keys(errors).some((key: string) => key.startsWith(group));
};

const gotoIncompleteQuestion = () => {
  currentActiveTab.value = RatingSurveyTab.SURVEY;
  if (surveyData.value?.questionnaire) {
    activeSurveyCategoryIndex.value = surveyData.value.questionnaire.findIndex(
      (category: DraftQuestionnaireReviewQuestionnaire) => {
        return category.status !== RatingSurveyStatus.COMPLETE;
      }
    );

    activeQuestionIndex.value = surveyData.value.questionnaire[
      activeSurveyCategoryIndex.value
    ].contents.findIndex((group: DraftQuestionnaireReviewQuestionnaireContent) => {
      return group.contents.some((question: DraftQuestionnaireReviewQuestionnaireTypeQuestion) => {
        return (
          !question.answers || (Array.isArray(question.answers) && question.answers.length === 0)
        );
      });
    });
  }
};

const onSaveRevision = async () => {
  const validateData = await validate();
  if (!validateData.valid || surveyStatus.value !== RatingSurveyStatus.COMPLETE) {
    if (checkGroupForm(validateData.errors, 'revision')) {
      currentActiveTab.value = RatingSurveyTab.REVISION;
      return;
    }

    if (surveyStatus.value !== RatingSurveyStatus.COMPLETE) {
      gotoIncompleteQuestion();
      return;
    }

    if (checkGroupForm(validateData.errors, 'attach')) {
      currentActiveTab.value = RatingSurveyTab.ATTACH;
      return;
    }
  }

  let revisionTypes = values.revision.revisionTypes ?? [];
  if (isHaveBasicInformationChange) {
    if (
      revisionTypes.includes(RevisionType.GameName) ||
      revisionTypes.includes(RevisionType.Developer)
    ) {
      revisionTypes = revisionTypes.filter(
        (type: string) => type !== RevisionType.GameName && type !== RevisionType.Developer
      );
      revisionTypes.push(RevisionType.Etc);
    }
  }

  const postRatingRevisionProductRequest: PostRatingRevisionProductRequest = {
    buildId: values.revision.buildItem ?? '',
    language: surveyData.value?.language ?? DEFAULT_LANG_CODE,
    usePreviousSurveyAnswer: values.revision.usePreviousSurveyAnswer ?? false,
    version: surveyData.value?.version || '',
    results: getAnswers(),
    productDescription: values.attach.productDescription ?? '',
    productControlDescription: values.attach.productControlDescription ?? '',
    fileUrls: values.attach.fileUrls ?? [],
    referenceDownloadLinks: values.attach.referenceDownloadLinks ?? [],
    revisionContent: values.revision.revisionContent ?? '',
    revisionTypes,
    infos: undefined,
    genreTagNos: undefined,
    defaultGenreTagNo: undefined
  };

  if (isHaveBasicInformationChange) {
    if (
      revisionProductSettingChanged.isChangedProductName ||
      revisionProductSettingChanged.isChangedDeveloper
    ) {
      postRatingRevisionProductRequest.infos = revisionProductSettingChanged.infos;
    }

    if (revisionProductSettingChanged.isChangedGenre) {
      postRatingRevisionProductRequest.genreTagNos = revisionProductSettingChanged.genreTagNos;
      postRatingRevisionProductRequest.defaultGenreTagNo =
        revisionProductSettingChanged.defaultGenreTag;
    }
  }

  try {
    const submitQuestionnaireReviewRes = await postRatingsRevisionProductsApi(
      productId,
      postRatingRevisionProductRequest
    );
    if (submitQuestionnaireReviewRes) {
      if (submitQuestionnaireReviewRes.ageRating === RatingAge.AdultOnly) {
        await showDialog({
          component: shallowRef(RatingSurveyNotifyDialogUnavailableTeen),
          severity: 'info'
        });
        return;
      }

      const confirm = await showDialog({
        component: shallowRef(RatingSurveyNotifyDialogConfirmRequestReview),
        props: {
          ageRating: submitQuestionnaireReviewRes.ageRating,
          contentInfos: submitQuestionnaireReviewRes.contentsInfos
        },
        severity: 'info'
      });

      if (confirm) {
        await postRatingProductsVerifyRequestApi(productId, {
          certId: submitQuestionnaireReviewRes.certId,
          buildId: values.revision.buildItem ?? ''
        });

        if (values.revision.isChangeIntlRating) {
          await postRatingsGlobalProductsApi(productId as string, {
            directYn: Confirmation.NO,
            globalRatings: values.revision.globalRatings
          });
        }

        await showAlert({
          content: [
            t('studio.prj_prod_mngmt.rating_build_review.self_review.post_review_guide3_1'),
            t('studio.prj_prod_mngmt.rating_build_review.self_review.post_review_guide3_2'),
            '<br/>',
            t('studio.prj_prod_mngmt.rating_build_review.self_review.post_review_guide3_3')
          ],
          confirmLabel: t('studio.common.popup_case_cf_btn')
        });
        emit('close', true);
      }
    }
  } catch (err) {
    const error = err as ErrorResponse;
    if (error.statusCode === STATUS_CODE.REVISION_ALREADY_IN_PROGRESS) {
      await showAlert({
        content: t('studio.build_rating_review.revision_already_in_progress_msg'),
        confirmLabel: t('studio.common.popup_case_cf_btn')
      });
      return;
    }

    showCommonErrorDialog(COMMON_ERROR_MESSAGE_KEY);
  }
};

const onSaveAppeal = async () => {
  const validateData = await validate();
  if (!validateData.valid || surveyStatus.value !== RatingSurveyStatus.COMPLETE) {
    if (checkGroupForm(validateData.errors, 'objection')) {
      currentActiveTab.value = RatingSurveyTab.OBJECTION;
      return;
    }

    if (surveyStatus.value !== RatingSurveyStatus.COMPLETE) {
      gotoIncompleteQuestion();
      return;
    }

    if (checkGroupForm(validateData.errors, 'attach')) {
      currentActiveTab.value = RatingSurveyTab.ATTACH;
      return;
    }
  }

  let postAnswers: string[] = [];
  if (values.objection.usePreviousSurveyAnswer) {
    postAnswers = answers.value.map(String);
  } else {
    postAnswers = getAnswers();
  }

  const postProductRatingAnswerRequest: PostProductRatingAnswerRequest = {
    groupId,
    adjustRating: values.objection.adjustRating ?? '',
    contentInfos: values.objection.contentInfos ?? [],
    reason: values.objection.reason ?? '',
    language: surveyData.value?.language ?? DEFAULT_LANG_CODE,
    results: postAnswers,
    serviceUniqueKey: serviceUniqueKey.value!,
    version: surveyData.value?.version ?? '',
    productDescription: values.attach.productDescription ?? '',
    productControlDescription: values.attach.productControlDescription ?? '',
    fileUrls: values.attach.fileUrls ?? [],
    referenceDownloadLinks: values.attach.referenceDownloadLinks ?? []
  };

  const confirm = await showConfirm({
    content: [
      t('studio.prj_prod_mngmt.rating_build_review.self_review.appeal_noti_popup_msg1'),
      '<br/>',
      t('studio.prj_prod_mngmt.rating_build_review.self_review.appeal_noti_popup_msg2')
    ],
    confirmLabel: t('studio.prj_prod_mngmt.rating_build_review.self_review.appeal_req_cf_btn'),
    cancelLabel: t('studio.common.popup_case_cancel_btn'),
    cancelVariant: 'outline'
  });

  if (confirm) {
    try {
      const res = await postProductRatingAnswerApi(productId, postProductRatingAnswerRequest);
      if (res) {
        if (res.success === true) {
          await showAlert({
            content: t(
              'studio.prj_prod_mngmt.rating_build_review.self_review.appeal_done_popup_msg1'
            ),
            confirmLabel: t('studio.common.popup_case_cf_btn'),
            confirmClasses: '!w-full !max-w-full'
          });

          emit('close', true);
          redirectTo(PRODUCT_RATING_URL);
        } else if (res.success === false) {
          if (res.objectionCode === ObjectionCode.NOT_EQUALS) {
            await showAlert({
              content: [
                `<p class="text-xl leading-lg font-bold text-on-surface-elevation-1 text-center">${t(
                  'studio.rating_build_review.self_review.appeal_rating_request_not_equal_expected_msg1',
                  {
                    changRequestRating: t(getAgeRatingText(res.requestRating), {
                      rating: res.requestRating
                    }),
                    surveyExpectedRating: t(getAgeRatingText(res.objectionRating), {
                      rating: res.objectionRating
                    })
                  }
                )}</p>`,
                `<p class="mt-8 text-center text-lg leading-lg text-on-surface-elevation-3">${t(
                  'studio.rating_build_review.self_review.appeal_rating_request_not_equal_expected_msg2'
                )}</p>`
              ],
              severity: 'info',
              confirmLabel: t('studio.common.popup_case_cf_btn'),
              size: 'md',
              contentClasses: 'pt-24 pb-[5rem]',
              confirmClasses: 'sm:min-w-264'
            });
            return;
          }

          if (res.objectionCode === ObjectionCode.ADULT_AND_REJECT) {
            await showDialog({
              component: shallowRef(RatingSurveyNotifyDialogUnavailableTeen),
              severity: 'info'
            });
          }
        }
      } else {
        showAlert({
          content: t(COMMON_ERROR_MESSAGE_KEY),
          severity: 'info',
          confirmLabel: t('studio.common.popup_case_cf_btn'),
          confirmClasses: '!w-full !max-w-full'
        });
      }
    } catch (error) {}
  }
};

const onSaveRegister = async () => {
  const validateData = await validate();
  if (!validateData.valid || surveyStatus.value !== RatingSurveyStatus.COMPLETE) {
    if (surveyStatus.value !== RatingSurveyStatus.COMPLETE) {
      gotoIncompleteQuestion();
      return;
    }

    if (checkGroupForm(validateData.errors, 'attach')) {
      currentActiveTab.value = RatingSurveyTab.ATTACH;
      return;
    }
  }

  const saveQuestionnaireReviewRequest: SubmitQuestionnaireReviewRequest = {
    groupId,
    buildId: buildInfo.value?.buildId ?? '',
    language: surveyData.value?.language ?? DEFAULT_LANG_CODE,
    results: getAnswers(),
    serviceUniqueKey: Number(productId as string),
    version: surveyData.value?.version || '',
    originNation: buildInfo.value?.originNation ?? '',
    productSummary: buildInfo.value?.productSummary ?? '',
    productDescription: values.attach.productDescription ?? '',
    productControlDescription: values.attach.productControlDescription ?? '',
    fileUrls: values.attach.fileUrls ?? [],
    referenceDownloadLinks: values.attach.referenceDownloadLinks ?? []
  };

  try {
    const submitQuestionnaireReviewRes = await submitQuestionnaireReviewApi(
      productId,
      saveQuestionnaireReviewRequest
    );
    if (submitQuestionnaireReviewRes) {
      if (submitQuestionnaireReviewRes.ageRating === RatingAge.AdultOnly) {
        await showDialog({
          component: shallowRef(RatingSurveyNotifyDialogUnavailableTeen),
          severity: 'info'
        });
        return;
      }

      const confirm = await showDialog({
        component: shallowRef(RatingSurveyNotifyDialogConfirmRequestReview),
        props: {
          ageRating: submitQuestionnaireReviewRes.ageRating,
          contentInfos: submitQuestionnaireReviewRes.contentInfos
        },
        severity: 'info'
      });

      if (confirm) {
        await postRatingProductsVerifyRequestApi(productId, {
          certId: submitQuestionnaireReviewRes.certId,
          buildId: buildInfo.value?.buildId ?? ''
        });

        await showAlert({
          content: [
            t('studio.prj_prod_mngmt.rating_build_review.self_review.post_review_guide3_1'),
            t('studio.prj_prod_mngmt.rating_build_review.self_review.post_review_guide3_2'),
            '<br/>',
            t('studio.prj_prod_mngmt.rating_build_review.self_review.post_review_guide3_3')
          ],
          confirmLabel: t('studio.common.popup_case_cf_btn')
        });
        emit('close', true);
        redirectTo(PRODUCT_RATING_URL);
      }
    }
  } catch (e) {
    await showCommonErrorDialog(COMMON_ERROR_MESSAGE_KEY);
  }
};

const onClickChangeTab = (value: RatingSurveyTab) => {
  currentActiveTab.value = value;
};

const onClickCloseDialog = () => {
  emit('close', false);
};

const onChangeCategory = (index: number) => {
  activeSurveyCategoryIndex.value = index;
  activeQuestionIndex.value = 0;
};

const onPrevious = () => {
  if (activeQuestionIndex.value > 0) {
    activeQuestionIndex.value -= 1;
  } else {
    activeSurveyCategoryIndex.value--;
    if (surveyData.value?.questionnaire[activeSurveyCategoryIndex.value].contents.length) {
      activeQuestionIndex.value =
        surveyData.value.questionnaire[activeSurveyCategoryIndex.value].contents.length - 1;
    }
  }
};

const onNext = () => {
  if (
    currentCategory.value?.contents &&
    activeQuestionIndex.value < currentCategory.value.contents.length - 1
  ) {
    activeQuestionIndex.value += 1;
  } else {
    activeSurveyCategoryIndex.value++;
    activeQuestionIndex.value = 0;
  }
};

const inactivateAllQuestionsAfter = (
  selectedQuestion: DraftQuestionnaireReviewQuestionnaireTypeQuestion
) => {
  if (surveyData.value) {
    let found = false;
    for (const category of surveyData.value.questionnaire) {
      for (const group of category.contents) {
        for (const question of group.contents) {
          const isSelectedQuestion = question.id === selectedQuestion.id;
          if (found) {
            if (question.no.split('.').length === 5) {
              question.hide = true;
            }
            question.active = false;
            question.answers = question.type === ChoiceType.Radio ? undefined : [];
          } else if (isSelectedQuestion) {
            found = true;
          }
        }
      }
    }
  }
};

const getQuestion = (questionId: number) => {
  if (surveyData.value) {
    for (const category of surveyData.value.questionnaire) {
      for (const group of category.contents) {
        for (const question of group.contents) {
          if (question.id === questionId) {
            return question;
          }
        }
      }
    }
  }
  return null;
};

// const getPreviousQuestion = (questionId: number) => {
//   if (surveyData.value) {
//     for (const category of surveyData.value.questionnaire) {
//       for (const group of category.contents) {
//         for (const question of group.contents) {
//           for (const choice of question.choice) {
//             if (choice.next && Number(choice.next) === questionId) {
//               return question;
//             }
//           }
//         }
//       }
//     }
//   }
//   return null;
// };

// const previousQuestionAnswered = (questionId: number): boolean => {
//   const previousQuestion = getPreviousQuestion(questionId);
//   if (!previousQuestion) {
//     return false;
//   }
//
//   const answers = previousQuestion.answers;
//   if (Array.isArray(answers)) {
//     return answers.length > 0;
//   }
//
//   return !!answers;
// };

const getCategory = (questionId: number) => {
  if (surveyData.value) {
    for (const category of surveyData.value.questionnaire) {
      for (const group of category.contents) {
        for (const question of group.contents) {
          if (question.id === questionId) {
            return category;
          }
        }
      }
    }
  }
  return null;
};

const setEndSurveyQuestion = (question: DraftQuestionnaireReviewQuestionnaireTypeQuestion) => {
  endQuestion.value = question;
  surveyStatus.value = RatingSurveyStatus.COMPLETE;
  surveyData.value!.questionnaire[activeSurveyCategoryIndex.value].status =
    RatingSurveyStatus.COMPLETE;
  totalAnswerCount.value = totalQuestionCount.value;
};

const onClickChoice = (
  question: DraftQuestionnaireReviewQuestionnaireTypeQuestion,
  nextId: string,
  _choiceId: number,
  event: Event
) => {
  isSubmittedReview.value = false;
  const target = event.target as HTMLInputElement;
  const value = target.checked;
  let isNeedCheckEndQuestion = false;
  if (question.type === ChoiceType.Checkbox) {
    if (!value) {
      if (!question.answers || (Array.isArray(question.answers) && question.answers.length === 0)) {
        inactivateAllQuestionsAfter(question);
      } else {
        isNeedCheckEndQuestion = true;
      }
    } else {
      isNeedCheckEndQuestion = true;
    }
  } else {
    inactivateAllQuestionsAfter(question);
    isNeedCheckEndQuestion = true;
  }

  if (isNeedCheckEndQuestion && nextId === SURVEY_NEXT.END) {
    setEndSurveyQuestion(question);
    return;
  }

  const nextQuestion = getQuestion(Number(nextId));
  if (value && nextQuestion) {
    nextQuestion.active = true;
  }

  setTimeout(() => {
    setTotalAnswerCountAndStatus();
  });
};

const getAnswers = () => {
  const selectedChoices: string[] = [];
  if (surveyData.value) {
    for (const category of surveyData.value.questionnaire) {
      for (const group of category.contents) {
        for (const question of group.contents) {
          if (question.type === ChoiceType.Radio) {
            if (question.answers) {
              selectedChoices.push(question.answers.toString());
            }
          } else if (question.type === ChoiceType.Checkbox) {
            if (question.answers) {
              selectedChoices.push(...(question.answers as number[]).map(String));
            }
          }
        }
      }
    }
  }
  return selectedChoices;
};

const setupAnswers = () => {
  if (surveyData.value) {
    totalQuestionCount.value = getTotalQuestionCount();
    firstQuestionNo.value = surveyData.value.questionnaire[0].contents[0].no;
    const lastQuestionnaireIndex = surveyData.value.questionnaire.length - 1;
    const lastContentQuestionnaireIndex =
      surveyData.value.questionnaire[lastQuestionnaireIndex].contents.length - 1;
    lastQuestionNo.value =
      surveyData.value.questionnaire[lastQuestionnaireIndex].contents[
        lastContentQuestionnaireIndex
      ].no;

    if (
      surveyData.value.questionnaire[0] &&
      surveyData.value.questionnaire[0].contents[0] &&
      surveyData.value.questionnaire[0].contents[0].contents[0]
    ) {
      surveyData.value.questionnaire[0].contents[0].contents[0].active = true;
    }
    for (const category of surveyData.value.questionnaire) {
      for (const group of category.contents) {
        for (const question of group.contents) {
          if (question.no.split('.').length === 5) {
            question.hide = true;
            // if (!previousQuestionAnswered(question.id)) {
            //   question.hide = true;
            // }
          }
          for (const choice of question.choice) {
            const found =
              answers.value.findIndex((choiceId: number) => choiceId === choice.id) !== -1;
            if (found) {
              if (choice.next) {
                if (choice.next === SURVEY_NEXT.END) {
                  endQuestion.value = question;
                } else {
                  const nextQuestion = getQuestion(Number(choice.next));
                  if (nextQuestion) {
                    nextQuestion.hide = false;
                    nextQuestion.active = true;
                  }
                }
              }

              if (question.type === ChoiceType.Radio) {
                question.answers = choice.id;
              } else if (question.answers) {
                (question.answers as Array<number>).push(choice.id);
              } else {
                question.answers = [choice.id];
              }
              question.active = true;
            }
          }
        }
      }
    }
  }

  setTotalAnswerCountAndStatus();
};

const setQuestionnaireReview = (
  res: DraftQuestionnaireReviewContents | QuestionnaireReviewResponse
) => {
  if (res.results) {
    answers.value = res.results.map(Number);
  }
  setFieldValue('attach', {
    productDescription:
      res.productDescription ??
      t('studio.prj_prod_mngmt.rating_build_review.self_review.info_game_descr_sample'),
    productControlDescription: res.productControlDescription,
    fileUrls: res.fileUrls ?? [],
    referenceDownloadLinks: res.referenceDownloadLinks ?? ['', '']
  });
};

const initCurrentActiveTab = () => {
  switch (type) {
    case SurveyDialogType.OBJECTION:
      currentActiveTab.value = RatingSurveyTab.OBJECTION;
      break;
    case SurveyDialogType.REVISION:
      currentActiveTab.value = RatingSurveyTab.REVISION;
      break;
    case SurveyDialogType.REGISTER:
    default:
      currentActiveTab.value = RatingSurveyTab.SURVEY;
  }
};

const initQuestionnaireReview = async () => {
  const surveyRes = await fetchQuestionnaireContentApi();
  if (surveyRes) {
    surveyData.value = surveyRes;
  }

  if (mode === RatingPageMode.EDIT) {
    let draftQuestionRes;

    if (props.buildId) {
      draftQuestionRes = await fetchDraftQuestionnaireReviewApi(productId, {
        buildId: props.buildId,
        languageCd: surveyData.value?.language || DEFAULT_LANG_CODE
      });
    }

    // on Revision type, if can't get draft question from current build_id, we will get draft question from released build
    if (
      !draftQuestionRes &&
      ratingProduct.value?.releasedRatings[0]?.buildId &&
      (type === SurveyDialogType.REVISION || type === SurveyDialogType.OBJECTION)
    ) {
      draftQuestionRes = await fetchDraftQuestionnaireReviewApi(productId, {
        buildId: ratingProduct.value?.releasedRatings[0].buildId,
        languageCd: surveyData.value?.language || DEFAULT_LANG_CODE
      });
    }

    if (draftQuestionRes) {
      serviceUniqueKey.value = draftQuestionRes.serviceUniqueKey;
      setQuestionnaireReview(draftQuestionRes.contents);

      buildInfo.value = {
        buildId: props.buildId || draftQuestionRes.buildId,
        language: surveyData.value?.language || DEFAULT_LANG_CODE,
        originNation: props.originNation || draftQuestionRes.contents.originNation,
        productSummary: props.productSummary || draftQuestionRes.contents.productSummary
      };

      if (type === SurveyDialogType.REVISION && revisionTypes.length === 0) {
        setFieldValue('revision.revisionTypes', draftQuestionRes.contents?.revisionTypes ?? []);
        setFieldValue('revision.revisionContent', draftQuestionRes.contents?.revisionContent);
      }
    } else {
      buildInfo.value = {
        buildId: props.buildId,
        language: surveyData.value?.language || DEFAULT_LANG_CODE,
        originNation: props.originNation,
        productSummary: props.productSummary
      };

      if (type === SurveyDialogType.OBJECTION || type === SurveyDialogType.REVISION) {
        const res = await fetchQuestionnaireReviewApi(productId, {
          languageCd: surveyData.value?.language || DEFAULT_LANG_CODE
        });
        if (res) {
          setQuestionnaireReview(res);
          serviceUniqueKey.value = res.productNo;
        }
      }
    }
  } else {
    const res = await fetchQuestionnaireReviewApi(productId, {
      languageCd: surveyData.value?.language || DEFAULT_LANG_CODE
    });
    if (res) {
      setQuestionnaireReview(res);
      serviceUniqueKey.value = res.productNo;
    }
  }
  setupAnswers();
};

const initCurrentRating = async () => {
  ratingProduct.value = await fetchRatingsProductsApi(productId);
  if (ratingProduct.value) {
    const mapGlobalRatings = (ratings: GlobalRatings[]) => {
      return ratings.map((item: GlobalRatings) => ({
        ratingBoardType: item.boardType,
        ageRating: item.ageRating,
        ratingId: item.ratingId
      }));
    };

    const globalRatings =
      !isEmpty(ratingProduct.value.releasedRatings) && isReleased.value
        ? mapGlobalRatings(ratingProduct.value.releasedGlobalRatings)
        : mapGlobalRatings(ratingProduct.value.requestedGlobalRatings);

    originGlobalRatings.value = JSON.parse(JSON.stringify(globalRatings));
    setFieldValue('revision.globalRatings', globalRatings);

    const ratingData = getCorrectRating(
      ratingProduct.value.requestRating,
      ratingProduct.value.releasedRatings
    );
    if (ratingData) {
      currentRating.value = Array.isArray(ratingData) ? ratingData[0] : ratingData;

      if (type === SurveyDialogType.OBJECTION) {
        if (mode === RatingPageMode.DETAIL) {
          currentRating.value = ratingProduct.value.releasedRatings[0];
          setFieldValue('objection.adjustRating', ratingProduct.value.requestRating.ageRating);
          setFieldValue('objection.reason', ratingProduct.value.requestRating.reason);
          setFieldValue('objection.contentInfos', ratingProduct.value.requestRating.contentInfos);
        } else {
          setFieldValue('objection.contentInfos', currentRating.value.contentInfos);
        }
      } else if (type === SurveyDialogType.REVISION) {
        if (mode === RatingPageMode.DETAIL) {
          setFieldValue(
            'revision.revisionTypes',
            ratingProduct.value?.requestRating.revisionInfo?.revisionTypes ?? []
          );
          setFieldValue(
            'revision.revisionContent',
            ratingProduct.value?.requestRating.revisionInfo?.revisionContent ?? ''
          );
        }
      }
    }
  }
};

const searchBuild = async () => {
  const buildRes = await fetchProductBuildUploadCompletedApi({
    gameId: buildSearchParams.value.gameId
  });
  if (buildRes) {
    buildItems.value = buildRes.map((item: Build) => ({
      label: `${item.buildNo} (${item.buildDescription || '-'})`,
      value: item.buildId
    }));

    if (!props.buildId) {
      setFieldValue('revision.buildItem', buildRes[0].buildId);
    }
  }
};

const init = async () => {
  initCurrentActiveTab();
  if (type === SurveyDialogType.REVISION) {
    await searchBuild();
  }
  await initCurrentRating();
  await initQuestionnaireReview();

  if (isHaveBasicInformationChange) {
    if (revisionProductSettingChanged.isChangedProductName) {
      setFieldValue('revision.revisionTypes', [
        ...values.revision.revisionTypes,
        RevisionType.GameName
      ]);
    }

    if (revisionProductSettingChanged.isChangedDeveloper) {
      setFieldValue('revision.revisionTypes', [
        ...values.revision.revisionTypes,
        RevisionType.Developer
      ]);
    }

    if (revisionProductSettingChanged.isChangedGenre) {
      setFieldValue('revision.revisionTypes', [
        ...values.revision.revisionTypes,
        RevisionType.Genre
      ]);
    }

    setFieldValue('revision.buildItem', ratingProduct.value?.releasedRatings[0].buildId);
  }

  isLoading.value = false;
};

init();
</script>
