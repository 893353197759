<!-- 28-1-6 -->
<template>
  <st-box>
    <st-form-title
      :formTitle="$t('studio.prj_prod_mngmt.rating_build_review.self_review.info_game_descr')"
      required
      class="mt-24"
    >
      <div class="shrink-0 ml-auto">
        <s-button
          size="xs"
          variant="outline"
          icon="ic-v2-media-av-fullscreen-fill"
          :isDisabled="isCodeView"
          @click="onClickGameDescriptionFullScreenOn"
        >
          {{ $t('studio.common.full_scr_btn') }}
        </s-button>
      </div>
    </st-form-title>
    <p class="text-xs leading-xs text-placeholder">
      <safe-html
        :html="$t('studio.prj_prod_mngmt.rating_build_review.self_review.info_game_descr_guide')"
      />
    </p>
    <!-- 에디터 -->
    <text-editor
      v-model="productDescriptionRef"
      :toolbarButtons="TOOLBAR_BUTTONS_FROALA"
      :config="editorConfig"
      :charCounterMax="WORD_COUNT_LIMIT"
      name="attach.productDescription"
      class="h-[46rem] mt-8"
      :rules="{
        required: $t(
          'studio.prj_prod_mngmt.rating_build_review.self_review.info_game_descr_req_val_msg'
        ),
        max_char_count: {
          length: WORD_COUNT_LIMIT,
          charCount: productDescriptionCharCount,
          message: $t('studio.common.def_key.exceed', {
            length: formatNumberMultipleWithCommas(WORD_COUNT_LIMIT)
          })
        }
      }"
      :disabled="mode === RatingPageMode.DETAIL"
      absoluteErrorMsg
      @onClickBtnHtmlCodeBlock="isCodeView = $event"
      @update:modelValue="updateProductDescription"
      @wordCount="onProductDescriptionCharCount"
    />

    <st-form-title
      :formTitle="
        $t('studio.prj_prod_mngmt.rating_build_review.self_review.info_game_create_method')
      "
      required
      class="mt-32"
    >
      <div class="shrink-0 ml-auto">
        <s-button
          size="xs"
          variant="outline"
          icon="ic-v2-media-av-fullscreen-fill"
          :isDisabled="isCodeView"
          @click="onClickGameControlMethodFullScreenOn"
        >
          {{ $t('studio.common.full_scr_btn') }}
        </s-button>
      </div>
    </st-form-title>
    <p class="text-xs leading-xs text-placeholder">
      <safe-html
        :html="
          $t('studio.prj_prod_mngmt.rating_build_review.self_review.info_game_create_method_guide')
        "
      />
    </p>
    <!-- 에디터 -->
    <text-editor
      v-model="productControlDescriptionRef"
      :toolbarButtons="TOOLBAR_BUTTONS_FROALA"
      :config="editorConfig"
      :charCounterMax="WORD_COUNT_LIMIT"
      name="attach.productControlDescription"
      class="h-[46rem] mt-8"
      :rules="{
        required: $t(
          'studio.prj_prod_mngmt.rating_build_review.self_review.info_game_create_method_req_val_msg'
        ),
        max_char_count: {
          length: WORD_COUNT_LIMIT,
          charCount: productControlDescriptionCharCount,
          message: $t('studio.common.def_key.exceed', {
            length: formatNumberMultipleWithCommas(WORD_COUNT_LIMIT)
          })
        }
      }"
      :disabled="mode === RatingPageMode.DETAIL"
      absoluteErrorMsg
      @onClickBtnHtmlCodeBlock="isCodeView = $event"
      @update:modelValue="updateProductControlDescription"
      @wordCount="onProductControlDescriptionCharCount"
    />
    <div class="flex items-center gap-4 mt-32">
      <div class="flex-1">
        <st-form-title
          :formTitle="$t('studio.prj_prod_mngmt.rating_build_review.self_review.attch_doc_tab')"
        />
        <p class="text-xs leading-xs text-placeholder">
          <safe-html
            :html="$t('studio.prj_prod_mngmt.rating_build_review.self_review.attch_doc_guide')"
          />
        </p>
      </div>
      <input-file name="attach.fileUrl" :base64="false" @fileSelect="handleFileSelect">
        <template #default="{ triggerUpload }">
          <s-button
            size="sm"
            variant="primary"
            class="shrink-0 !min-w-84"
            :isDisabled="mode === RatingPageMode.DETAIL"
            @click="
              () => {
                if (fileUrls.length === 10) {
                  showMaxAttachFilePopup();
                } else {
                  triggerUpload();
                }
              }
            "
          >
            {{ $t('studio.prj_prod_mngmt.rating_build_review.self_review.attch_doc_add_btn') }}
          </s-button>
        </template>
      </input-file>
    </div>
    <div class="mt-12">
      <div
        v-if="fileUrls?.length > 0"
        class="flex flex-wrap gap-8 mb-24 pb-24 border-b border-solid border-border"
      >
        <s-chips
          v-for="(fileUrl, index) of fileUrls"
          :key="index"
          variant="fill"
          size="md"
          :hasCloseIcon="mode === RatingPageMode.EDIT"
          @close="removeFileUrl(index)"
          @click="downLoadFile(fileUrl.fileUrl, fileUrl.fileName)"
        >
          {{ fileUrl.fileName }}
        </s-chips>
      </div>
      <div class="flex flex-col gap-8">
        <div
          v-for="(_link, index) of referenceDownloadLinks"
          :key="index"
          class="flex gap-4 items-baseline"
        >
          <div class="flex-1">
            <input-text
              size="sm"
              variant="outline"
              placeholder="http://"
              :name="`attach.referenceDownloadLinks[${index}]`"
              :rules="{
                url: $t(
                  'studio.prj_prod_mngmt.rating_build_review.self_review.attch_doc_link_format_val_msg'
                ),
                max_length: {
                  length: 200,
                  message: $t('studio.common.def_key.exceed', { length: 200 })
                }
              }"
              :countable="false"
              :disabled="mode === RatingPageMode.DETAIL"
            />
          </div>
          <button
            v-if="mode === RatingPageMode.EDIT && referenceDownloadLinks.length > LINK.MIN"
            type="button"
            class="shrink-0"
            @click="removeLink(index)"
          >
            <s-icon
              size="xl"
              icon="ic-v2-control-close-line"
              srOnlyText="Delete"
              class="text-on-surface-elevation-2"
            />
          </button>
        </div>
        <s-button
          v-if="referenceDownloadLinks?.length < LINK.MAX"
          size="sm"
          variant="outline"
          icon="ic-v2-control-add-line"
          iconClass="!mr-0 ml-2"
          class="flex-row-reverse w-full"
          :isDisabled="mode === RatingPageMode.DETAIL"
          @click="addMoreLink"
        >
          {{
            $t('studio.prj_prod_mngmt.rating_build_review.self_review.attch_doc_add_dl_link_btn')
          }}
        </s-button>
      </div>
    </div>
  </st-box>
</template>
<script setup lang="ts">
import { useHead } from '@unhead/vue';
import { useFieldValue, useFormErrors, useSetFieldValue, useSetFormErrors } from 'vee-validate';
import { ref, shallowRef, toRefs, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { uploadFileApi } from '@/apis/file.api';
import safeHtml from '@/components/common/safe-html.vue';
import StBox from '@/components/common/st-box.vue';
import StFormTitle from '@/components/common/st-form-title.vue';
import RatingSurveyDialogTextEditorFullScreen from '@/components/rating/dialog/text-editor-dialog.vue';
import InputFile from '@/components/validation/input-file.vue';
import InputText from '@/components/validation/input-text.vue';
import TextEditor from '@/components/validation/text-editor.vue';
import { showAlert, showDialog } from '@/composables/useDialog';
import { TOOLBAR_BUTTONS_FROALA } from '@/constants/froala.const';
import { RatingPageMode } from '@/enums/rating.enum';
import type { AttachedFile } from '@/types/common/file.type';
import { formatNumberMultipleWithCommas } from '@/utils/currency.util';
import { checkFileNameMaxLength, downLoadFile } from '@/utils/file.util';
import { initFullScreenEditor } from '@/utils/froala.util';

const ACCEPTED_MAX_SIZE = 2 * 1024 * 1024 * 1024;
const WORD_COUNT_LIMIT = 10000;
const LINK = {
  MIN: 2,
  MAX: 10
};

useHead({
  bodyAttrs: {
    class: 'overflow-hidden'
  }
});

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    mode: RatingPageMode;
    productDescription: string;
    productControlDescription: string;
  }>(),
  {
    mode: RatingPageMode.EDIT,
    productDescription: '',
    productControlDescription: ''
  }
);

const { mode, productDescription, productControlDescription } = toRefs(props);

const productDescriptionRef = ref<string>(productDescription.value);
const productControlDescriptionRef = ref<string>(productControlDescription.value);
const productDescriptionCharCount = ref<number>(0);
const productControlDescriptionCharCount = ref<number>(0);

const setFormErrors = useSetFormErrors();

const referenceDownloadLinks = useFieldValue<string[]>('attach.referenceDownloadLinks');
const fileUrls = useFieldValue<AttachedFile[]>('attach.fileUrls');

const setProductDescription = useSetFieldValue('attach.productDescription');
const setProductControlDescription = useSetFieldValue('attach.productControlDescription');
const setReferenceDownloadLinks = useSetFieldValue('attach.referenceDownloadLinks');
const setFileUrls = useSetFieldValue('attach.fileUrls');

const error = useFormErrors();

const isCodeView = ref<boolean>(false);

const editorConfig = {
  attribution: false,
  charCounterCount: true,
  wordCounterCount: false,
  placeholderText: '',
  events: {
    initialized: function() {
      const editor = this as any;
      initFullScreenEditor(editor);
    }
  }
};

const onProductDescriptionCharCount = (count: number) => {
  productDescriptionCharCount.value = count;
};

const onProductControlDescriptionCharCount = (count: number) => {
  productControlDescriptionCharCount.value = count;
};

const updateProductDescription = (value: string) => {
  setProductDescription(value);
};

const updateProductControlDescription = (value: string) => {
  setProductControlDescription(value);
};

const onClickGameDescriptionFullScreenOn = async () => {
  const content = await showDialog<string>({
    component: shallowRef(RatingSurveyDialogTextEditorFullScreen),
    props: {
      title: t('studio.prj_prod_mngmt.rating_build_review.self_review.info_game_descr'),
      content: productDescriptionRef.value,
      wordCountLimit: WORD_COUNT_LIMIT,
      toolbarButtons: TOOLBAR_BUTTONS_FROALA,
      disabled: mode.value === RatingPageMode.DETAIL,
      requiredMessage: t(
        'studio.prj_prod_mngmt.rating_build_review.self_review.info_game_descr_req_val_msg'
      )
    },
    severity: 'info'
  });

  productDescriptionRef.value = content;
  setProductDescription(content);
};

const onClickGameControlMethodFullScreenOn = async () => {
  const content = await showDialog<string>({
    component: shallowRef(RatingSurveyDialogTextEditorFullScreen),
    props: {
      title: t('studio.prj_prod_mngmt.rating_build_review.self_review.info_game_create_method'),
      content: productControlDescriptionRef.value,
      wordCountLimit: WORD_COUNT_LIMIT,
      toolbarButtons: TOOLBAR_BUTTONS_FROALA,
      disabled: mode.value === RatingPageMode.DETAIL,
      requiredMessage: t(
        'studio.prj_prod_mngmt.rating_build_review.self_review.info_game_create_method_req_val_msg'
      )
    },
    severity: 'info'
  });

  productControlDescriptionRef.value = content;
  setProductControlDescription(content);
};

const addMoreLink = () => {
  setReferenceDownloadLinks([...referenceDownloadLinks.value, '']);
};

const removeLink = (index: number) => {
  setFormErrors({
    [`attach.referenceDownloadLinks[${index}]`]: []
  });
  for (let i = index + 1; i < referenceDownloadLinks.value.length; i++) {
    setFormErrors({
      [`attach.referenceDownloadLinks[${i - 1}]`]:
        error.value[`attach.referenceDownloadLinks[${i}]`]
    });
  }
  setReferenceDownloadLinks(
    referenceDownloadLinks.value.filter((_: string, i: number) => i !== index)
  );
};

const handleFileSelect = async (files: File[]) => {
  if (files[0].size > ACCEPTED_MAX_SIZE) {
    showAlert({
      content: [
        t('studio.prj_prod_mngmt.rating_build_review.self_review.attch_doc_size_limit_val_msg1'),
        '<br/>',
        t('studio.prj_prod_mngmt.rating_build_review.self_review.attch_doc_size_limit_val_msg2')
      ]
    });

    return;
  }
  if (!(await checkFileNameMaxLength(files[0].name))) {
    return;
  }
  const result = await uploadFileApi([files[0]], 'images');
  if (result) {
    setFileUrls([
      ...fileUrls.value,
      {
        fileName: files[0].name,
        fileSize: files[0].size,
        fileUrl: result[0].linkCdn
      }
    ]);
  }
};

const removeFileUrl = async (index: number) => {
  setFileUrls(fileUrls.value.filter((_: AttachedFile, i: number) => i !== index));
};

const showMaxAttachFilePopup = () => {
  showAlert({
    content: [
      t('studio.prj_prod_mngmt.rating_build_review.self_review.attch_doc_num_limit_val_msg1'),
      '<br/>',
      t('studio.prj_prod_mngmt.rating_build_review.self_review.attch_doc_num_limit_val_msg2')
    ]
  });
};

watch(productDescription, (value: string) => {
  productDescriptionRef.value = value;
});

watch(productControlDescription, (value: string) => {
  productControlDescription.value = value;
});
</script>
