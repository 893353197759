<!-- RatingSurveyStep -->
<template>
  <button
    type="button"
    class="inline-flex items-center gap-4 pl-12 pr-20 py-[1rem] rounded-3xl"
    :class="{ 'bg-surface-layer shadow-[0_.4rem_1rem_rgba(0,51,150,.07)]': isCurrent }"
  >
    <rating-survey-progress-state-icon :status="status" />
    <span
      class="text-md font-medium leading-sm whitespace-nowrap"
      :class="isCurrent ? 'text-brand-primary' : 'text-on-surface-elevation-2'"
    >
      {{ stepTitle }}
    </span>
  </button>
</template>
<script setup lang="ts">
import { toRefs } from 'vue';

import RatingSurveyProgressStateIcon from '@/components/rating/rating-survey-progress-state-icon.vue';
import type { RatingSurveyStatus } from '@/enums/rating.enum';

// @TODO :: props 3개를 진행률로 받아서 처리 > rating-survey-progress-state-icon.vue 포함
const props = withDefaults(
  defineProps<{
    stepTitle: string;
    isCurrent?: boolean;
    status: RatingSurveyStatus;
  }>(),
  {
    isCurrent: false
  }
);

const { stepTitle, isCurrent, status } = toRefs(props);
</script>
