<!-- 28-1-6-A -->
<template>
  <s-dialog to="rating-survey-dialog-attach-full-screen" :open="true">
    <s-dialog-panel :id="uuid" class="!w-full !h-full !max-h-[initial] !rounded-none">
      <div
        class="flex h-full w-full flex-col bg-background-variant-2 px-32 pt-32 pb-48 dark:bg-surface-variant-1"
      >
        <div class="mb-16 flex shrink-0 items-center gap-16">
          <h2 class="text-xl font-bold leading-lg text-on-surface-elevation-1">
            {{ title }}
          </h2>
          <s-icon
            size="xs"
            icon="ic-v2-control-required-fill"
            class="text-tint-red-a400 self-start ml-[-1.2rem]"
          />
          <div class="ml-auto shrink-0">
            <s-button
              size="xs"
              variant="outline"
              icon="ic-v2-media-av-fullscreen-exit-fill"
              :isDisabled="isCodeView"
              @click="handleCloseDialog"
            >
              {{ $t('studio.prj_prod_mngmt.prod_home.create_term.btn_exit_fullscr') }}
            </s-button>
          </div>
        </div>
        <!-- 에디터 -->
        <text-editor
          v-model="textContent"
          name="textContent"
          :config="editorConfig"
          :charCounterMax="wordCountLimit"
          :toolbarButtons="toolbarButtons"
          class="flex-1 min-h-0"
          absoluteErrorMsg
          :disabled="disabled"
          :rules="{
            required: requiredMessage,
            max_char_count: {
              length: wordCountLimit,
              charCount: charCount,
              message: $t('studio.common.def_key.exceed', {
                length: formatNumberMultipleWithCommas(wordCountLimit)
              })
            }
          }"
          @onClickBtnHtmlCodeBlock="isCodeView = $event"
          @wordCount="onCharCount"
        />
      </div>
    </s-dialog-panel>
  </s-dialog>

  <s-portal-target name="rating-survey-dialog-attach-full-screen" />
</template>
<script setup lang="ts">
import { useHead } from '@unhead/vue';
import { useForm } from 'vee-validate';
import { onMounted, ref, toRefs } from 'vue';

import TextEditor from '@/components/validation/text-editor.vue';
import { formatNumberMultipleWithCommas } from '@/utils/currency.util';
import { generateUUID } from '@/utils/uuid.util';

useHead({
  bodyAttrs: {
    class: 'overflow-hidden'
  }
});

const uuid: string = `stds-dialog-${generateUUID()}`;

const props = defineProps<{
  title: string;
  content: string;
  wordCountLimit: number;
  toolbarButtons: string[];
  disabled: boolean;
  requiredMessage?: string;
}>();

const emits = defineEmits<{
  close: [v: string];
}>();

const { content } = toRefs(props);

const { setValues } = useForm({
  initialValues: {
    textContent: ''
  }
});

const textContent = ref<string>(content.value);
const charCount = ref(0);

const editorConfig = {
  attribution: false,
  charCounterCount: true,
  wordCounterCount: false,
  placeholderText: '',
  height: '50rem'
};

const isCodeView = ref<boolean>(false);

const onCharCount = (count: number) => {
  charCount.value = count;
};

const handleCloseDialog = () => {
  emits('close', textContent.value);
};

onMounted(async () => {
  setValues({ textContent: content.value });
});
</script>
