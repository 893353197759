<template>
  <component
    :is="questionComponent"
    :question="question"
    :disabled="disabled"
    @onClickChoice="onClickChoice"
  />
</template>
<script setup lang="ts">
import { computed, defineAsyncComponent } from 'vue';

import { LINE_RADIO } from '@/constants/rating.const';
import { ChoiceType } from '@/enums/rating.enum';
import type { DraftQuestionnaireReviewQuestionnaireQuestion } from '@/types/rating/rating.response.type';

const props = defineProps<{
  question: DraftQuestionnaireReviewQuestionnaireQuestion;
  disabled: boolean;
}>();

const emit = defineEmits<{
  onClickChoice: [
    question: DraftQuestionnaireReviewQuestionnaireQuestion,
    nextId: string,
    choiceId: number,
    value: Event
  ];
}>();

const onClickChoice = (
  question: DraftQuestionnaireReviewQuestionnaireQuestion,
  nextId: string,
  choiceId: number,
  value: Event
) => {
  emit('onClickChoice', question, nextId, choiceId, value);
};

const questionComponent = computed(() => {
  switch (props.question.type) {
    case ChoiceType.Checkbox:
      return defineAsyncComponent(() => import('@/components/rating/question/survey-checkbox.vue'));
    case ChoiceType.Radio:
    default:
      if (LINE_RADIO.includes(props.question.no)) {
        return defineAsyncComponent(
          () => import('@/components/rating/question/survey-line-radio.vue')
        );
      }
      return defineAsyncComponent(() => import('@/components/rating/question/survey-radio.vue'));
  }
});
</script>
