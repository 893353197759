<!-- 28-1-8 popup -->
<template>
  <s-dialog to="stds-rating-survey-request-review-dialog" size="md" :open="true">
    <s-dialog-overlay />
    <s-dialog-panel>
      <s-dialog-content>
        <div class="pt-48 pb-24">
          <p class="text-xl leading-lg font-bold text-on-surface-elevation-1 text-center">
            {{ $t(getAgeRatingText(ageRating), { rating: ageRating }) }}
          </p>
          <p class="text-xl leading-lg font-bold text-on-surface-elevation-1 text-center">
            {{ translateList(convertRatingContentInfosToTranslationText(contentInfos)) }}
          </p>
          <p class="text-xl leading-lg font-bold text-on-surface-elevation-1 text-center">
            <safe-html
              :html="
                $t('studio.prj_prod_mngmt.rating_build_review.self_review.post_review_guide1_2')
              "
            />
          </p>
          <p class="mt-8 text-center text-lg font-regular text-on-surface-elevation-3 leading-lg">
            <safe-html
              :html="
                $t('studio.prj_prod_mngmt.rating_build_review.self_review.post_review_guide1_3')
              "
            />
          </p>
          <p class="mt-8 text-center text-lg font-regular text-on-surface-elevation-3 leading-lg">
            <safe-html
              :html="
                $t('studio.prj_prod_mngmt.rating_build_review.self_review.post_review_guide1_4')
              "
            />
          </p>
        </div>
      </s-dialog-content>
      <s-dialog-footer>
        <s-button variant="outline" @click="onClose">
          {{ $t('studio.common.popup_case_cancel_btn') }}
        </s-button>
        <s-button @click="onConfirm">
          {{ $t('studio.prj_prod_mngmt.rating_build_review.self_review.review_req_cf_btn') }}
        </s-button>
      </s-dialog-footer>
    </s-dialog-panel>
  </s-dialog>

  <s-portal-target name="stds-rating-survey-request-review-dialog" />
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import safeHtml from '@/components/common/safe-html.vue';
import type { RatingAge } from '@/enums/rating.enum';
import { convertRatingContentInfosToTranslationText, getAgeRatingText } from '@/utils/rating.util';

const { t } = useI18n();

withDefaults(
  defineProps<{
    ageRating: RatingAge;
    contentInfos: string[];
  }>(),
  {
    contentInfos: () => []
  }
);

const emits = defineEmits<{
  close: [v: boolean];
}>();

const onClose = () => {
  emits('close', false);
};

const onConfirm = () => {
  emits('close', true);
};

const translateList = (contentInfos: string[]) => {
  const list = contentInfos.map((contentInfo: string) => {
    return t(contentInfo);
  });

  return list.join(', ');
};
</script>
