<!-- 28-3-2 -->
<template>
  <st-box>
    <st-folding-evaluate
      :evaluateTitle="
        $t('studio.prj_prod_mngmt.rating_build_review.self_review.report_change.basic_info')
      "
    >
      <div
        class="grid grid-cols-2 gap-y-20 gap-x-8 bg-[var(--stds-glob-color-gray20)] rounded-3xl p-20"
      >
        <!-- @TODO :: API Data Mapping -->
        <st-folding-evaluate-item
          class="col-span-2"
          :titleValue="
            $t(
              'studio.prj_prod_mngmt.rating_build_review.self_review.report_change.basic_info_name'
            )
          "
          :dataValue="ratingProduct?.gameName"
        />
        <st-folding-evaluate-item
          class="col-span-2"
          :titleValue="
            $t(
              'studio.prj_prod_mngmt.rating_build_review.self_review.report_change.basic_info_company'
            )
          "
          :dataValue="ratingProduct?.developer"
        />
        <st-folding-evaluate-item
          class="col-span-2"
          :titleValue="
            $t(
              'studio.prj_prod_mngmt.rating_build_review.self_review.report_change.basic_info_genre'
            )
          "
          :dataValue="ratingProduct?.defaultGenreTag"
        />
      </div>

      <div class="mt-4">
        <h3 class="text-xl leading-lg break-all font-bold text-on-surface-elevation-2">
          {{
            $t('studio.prj_prod_mngmt.rating_build_review.self_review.report_change.review_info')
          }}
        </h3>
        <div
          class="grid grid-cols-2 gap-y-20 gap-x-8 bg-[var(--stds-glob-color-gray20)] rounded-3xl mt-8 p-20"
        >
          <!-- TODO HAO waiting translation key -->
          <st-folding-evaluate-item
            class="col-span-2"
            :titleValue="
              $t(
                'studio.prj_prod_mngmt.rating_build_review.self_review.report_change.review_rating'
              )
            "
            :dataValue="
              $t(getAgeRatingText(currentRating?.ageRating), { rating: currentRating?.ageRating })
            "
          />
          <st-folding-evaluate-item
            class="col-span-2"
            :titleValue="
              $t(
                'studio.prj_prod_mngmt.rating_build_review.self_review.report_change.review_category'
              )
            "
            :dataValue="
              translateList(convertRatingContentInfosToTranslationText(currentRating?.contentInfos))
            "
          />
        </div>
      </div>
    </st-folding-evaluate>
  </st-box>

  <st-box class="mt-24">
    <template v-if="!isChangeBasicInformation">
      <st-form-title :formTitle="$t('studio.build_run_test.filter_search_by_build_no')" required />
      <div class="relative mb-32">
        <dropdown
          name="revision.buildItem"
          :options="buildItems"
          :placeholder="
            $t(
              'studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.grac_self_review.build_place_holder'
            )
          "
          :emptySearchText="
            $t(
              'studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.grac_self_review.build_no_result'
            )
          "
          :noDataText="
            $t('studio.build_rating_review.self_review.build_search_none_registered_build')
          "
          :dropdownProps="{
            size: 'lg',
            variant: 'line',
            distance: 0,
            offset: [0, 0],
            placement: 'bottom-start',
            class: 'w-full mt-4'
          }"
          searchable
          closeOnClick
          :rules="{
            required: $t(
              'studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.grac_self_review.build_required_msg'
            )
          }"
          :disabled="mode === RatingPageMode.DETAIL"
        />
      </div>
    </template>

    <st-form-title
      :formTitle="
        $t('studio.prj_prod_mngmt.rating_build_review.self_review.report_change.changed_info')
      "
      required
    />
    <p class="text-xs leading-xs text-placeholder">
      <safe-html
        :html="
          $t(
            'studio.prj_prod_mngmt.rating_build_review.self_review.report_change.changed_info_guide'
          )
        "
      />
    </p>
    <div class="mt-12 space-y-8">
      <template v-if="isChangeBasicInformation">
        <checkbox-group
          :options="changeOptions"
          name="revision.revisionTypes"
          size="sm"
          class="flex flex-col gap-4"
          disabled
        >
          <template #option-0="{ value, label }">
            <s-checkbox
              :value="value"
              v-bind="{ align: 'middle', size: 'sm' }"
              class="flex items-center"
            >
              {{ $t(label) }}
            </s-checkbox>
            <div class="flex flex-col rounded-3xl gap-8 bg-[#FCFCFD] px-20 py-[1rem] mt-4">
              <div class="flex flex-col gap-4 font-medium">
                <s-text as="span" role="text4" class="text-on-surface-elevation-4">
                  {{ $t('studio.prj_prod_mngmt.rating_build_review.report_change_item_name_kr') }}
                </s-text>
                <s-text as="span" role="text3" class="text-on-surface-elevation-2">
                  {{ revisionProductSettingChanged.productNameKo }}
                </s-text>
              </div>
              <div class="flex flex-col gap-4 font-medium">
                <s-text as="span" role="text4" class="text-on-surface-elevation-4">
                  {{ $t('studio.prj_prod_mngmt.rating_build_review.report_change_item_name_en') }}
                </s-text>
                <s-text as="span" role="text3" class="text-on-surface-elevation-2">
                  {{ revisionProductSettingChanged.productNameEn }}
                </s-text>
              </div>
            </div>
          </template>
          <template #option-1="{ value, label }">
            <s-checkbox
              :value="value"
              v-bind="{ align: 'middle', size: 'sm' }"
              class="flex items-center"
            >
              {{ $t(label) }}
            </s-checkbox>
            <div
              class="flex items-center min-h-[7rem] rounded-3xl bg-[#FCFCFD] px-20 py-[1rem] mt-4"
            >
              <s-text as="p" role="text3" class="font-medium text-on-surface-elevation-2">
                {{ revisionProductSettingChanged.developer }}
              </s-text>
            </div>
          </template>
          <template #option-2="{ value, label }">
            <s-checkbox
              :value="value"
              v-bind="{ align: 'middle', size: 'sm' }"
              class="flex items-center"
            >
              {{ $t(label) }}
            </s-checkbox>
            <div
              class="flex items-center min-h-[7rem] rounded-3xl bg-[#FCFCFD] px-20 py-[1rem] mt-4"
            >
              <s-text as="p" role="text3" class="font-medium text-on-surface-elevation-2">
                {{ revisionProductSettingChanged.defaultGenreTagName }}
              </s-text>
            </div>
          </template>
        </checkbox-group>
      </template>
      <template v-else>
        <checkbox-group
          :options="changeOptions"
          name="revision.revisionTypes"
          size="sm"
          class="flex flex-col gap-4"
          :rules="{
            required: $t(
              'studio.prj_prod_mngmt.rating_build_review.self_review.report_change.req_val_msg'
            )
          }"
          :disabled="mode === RatingPageMode.DETAIL"
        />
      </template>

      <!-- <div v-if="!isChangeBasicInformation" class="!mt-[3px]">
        <checkbox
          v-model="isChangeIntlRating"
          name="revision.isChangeIntlRating"
          :options="{
            size: 'md',
            id: 'changedIntlRating'
          }"
          :disabled="mode === RatingPageMode.DETAIL"
        >
          <span class="leading-lg text-on-surface-elevation-2">
            {{
              $t(
                'studio.prj_prod_mngmt.rating_build_review.self_review.report_change.changed_info_intl_rating'
              )
            }}
          </span>
        </checkbox>
      </div>
      <international-organization-form v-show="isChangeIntlRating" name="revision.globalRatings" :isDirect="isDirect!" noValidation /> -->
    </div>

    <st-form-title
      :formTitle="
        $t(
          'studio.prj_prod_mngmt.rating_build_review.self_review.report_change.changed_info_detail'
        )
      "
      class="mt-32"
    >
      <div class="shrink-0 ml-auto">
        <s-button
          size="xs"
          variant="outline"
          icon="ic-v2-media-av-fullscreen-fill"
          @click="onClickFullScreenOn"
        >
          {{ $t('studio.common.full_scr_btn') }}
        </s-button>
      </div>
    </st-form-title>
    <st-textarea
      name="revision.revisionContent"
      :placeholder="''"
      :maxLength="WORD_COUNT_LIMIT.toString()"
      :rules="{
        max_length: {
          length: WORD_COUNT_LIMIT,
          message: $t('studio.common.def_key.exceed', {
            length: formatNumberMultipleWithCommas(WORD_COUNT_LIMIT)
          })
        }
      }"
      containerClass="mt-16 h-240"
      :disabled="mode === RatingPageMode.DETAIL"
    />

    <div
      v-if="!isChangeBasicInformation || revisionProductSettingChanged.isChangedGenre"
      class="mt-32"
    >
      <checkbox
        align="middle"
        name="revision.usePreviousSurveyAnswer"
        :options="{
          size: 'sm',
          id: 'revisionUsePreviousSurveyAnswer'
        }"
        :disabled="mode === RatingPageMode.DETAIL"
      >
        <span class="leading-lg text-on-surface-elevation-1">
          {{
            $t(
              'studio.prj_prod_mngmt.rating_build_review.self_review.appeal_use_existed_info_chkbox'
            )
          }}
        </span>
      </checkbox>
    </div>
  </st-box>
  <div class="flex justify-center gap-16 mt-40">
    <s-button variant="secondary" size="lg" class="!min-w-160" @click="onNext">
      {{ $t('studio.prj_prod_mngmt.rating_build_review.self_review.appeal_next_btn') }}
    </s-button>
  </div>
</template>
<script setup lang="ts">
import { useHead } from '@unhead/vue';
import { useFieldValue, useSetFieldValue } from 'vee-validate';
import { shallowRef, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

import safeHtml from '@/components/common/safe-html.vue';
import StBox from '@/components/common/st-box.vue';
import StFoldingEvaluate from '@/components/common/st-folding-evaluate.vue';
import StFoldingEvaluateItem from '@/components/common/st-folding-evaluate-item.vue';
import StFormTitle from '@/components/common/st-form-title.vue';
import RatingSurveyDialogTextareaFullScreen from '@/components/rating/dialog/textarea-dialog.vue';
import Checkbox from '@/components/validation/checkbox.vue';
import CheckboxGroup from '@/components/validation/checkbox-group.vue';
import Dropdown from '@/components/validation/dropdown.vue';
import StTextarea from '@/components/validation/textarea.vue';
import { showDialog } from '@/composables/useDialog';
import { RatingPageMode, RevisionType } from '@/enums/rating.enum';
import type { FormOptionGroup } from '@/types/common/form.type';
import type { StartSurveyRequest } from '@/types/rating/rating.request.type';
import type {
  RatingProductResponse,
  ReleasedRatings,
  RequestRating
} from '@/types/rating/rating.response.type';
import type { RatingRevisionForm, RevisionProductSettingChanged } from '@/types/rating/rating.type';
import { formatNumberMultipleWithCommas } from '@/utils/currency.util';
import { convertRatingContentInfosToTranslationText, getAgeRatingText } from '@/utils/rating.util';

const WORD_COUNT_LIMIT = 10000;

useHead({
  bodyAttrs: {
    class: 'overflow-hidden'
  }
});

const props = withDefaults(
  defineProps<{
    mode?: RatingPageMode;
    ratingProduct?: RatingProductResponse;
    currentRating?: RequestRating | ReleasedRatings;
    buildInfo?: StartSurveyRequest;
    revisionProductSettingChanged?: RevisionProductSettingChanged;
    buildItems: FormOptionGroup<string | number>[];
  }>(),
  {
    mode: RatingPageMode.EDIT,
    ratingProduct: undefined,
    currentRating: undefined,
    buildInfo: undefined,
    buildItems: () => [],
    revisionProductSettingChanged: () => ({
      isChangedProductName: false,
      isChangedDeveloper: false,
      isChangedGenre: false,
      originProductNameKo: '',
      originProductNameEn: '',
      originDeveloper: '',
      originDefaultGenreTag: undefined,
      originGenreTagNos: undefined,
      productNameKo: '',
      productNameEn: '',
      developer: '',
      defaultGenreTag: undefined,
      defaultGenreTagName: undefined,
      genreTagNos: undefined,
      infos: []
    })
  }
);

const emit = defineEmits<{
  onUpdate: [values: RatingRevisionForm];
  onNext: [];
}>();

const { ratingProduct, currentRating, revisionProductSettingChanged, buildItems } = toRefs(props);
const isChangeBasicInformation =
  revisionProductSettingChanged.value.isChangedProductName ||
  revisionProductSettingChanged.value.isChangedDeveloper ||
  revisionProductSettingChanged.value.isChangedGenre;

const setPreviousSurveyAnswer = useSetFieldValue('revision.usePreviousSurveyAnswer');
const revisionContent = useFieldValue('revision.revisionContent');
const setRevisionContent = useSetFieldValue('revision.revisionContent');

const { t } = useI18n();

// const isChangeIntlRating = ref(false);
// const isDirect = currentRating.value && (currentRating.value as ReleasedRatings).directYn === Confirmation.YES;

const changeOptions = isChangeBasicInformation
  ? [
    {
      label: t(
        'studio.prj_prod_mngmt.rating_build_review.self_review.report_change.changed_info_game_name'
      ),
      value: RevisionType.GameName
    },
    {
      label: t(
        'studio.prj_prod_mngmt.rating_build_review.self_review.report_change.changed_info_company'
      ),
      value: RevisionType.Developer
    },
    {
      label: t(
        'studio.prj_prod_mngmt.rating_build_review.self_review.report_change.changed_info_genre'
      ),
      value: RevisionType.Genre
    }
  ]
  : [
    {
      label: t(
        'studio.prj_prod_mngmt.rating_build_review.self_review.report_change.changed_info_background'
      ),
      value: RevisionType.Background
    },
    {
      label: t(
        'studio.prj_prod_mngmt.rating_build_review.self_review.report_change.changed_info_sys_ui'
      ),
      value: RevisionType.Ui
    },
    {
      label: t(
        'studio.prj_prod_mngmt.rating_build_review.self_review.report_change.changed_info_billing_sys'
      ),
      value: RevisionType.Billing
    },
    {
      label: t(
        'studio.prj_prod_mngmt.rating_build_review.self_review.report_change.changed_info_mission_scenario'
      ),
      value: RevisionType.Scenario
    },
    {
      label: t(
        'studio.prj_prod_mngmt.rating_build_review.self_review.report_change.changed_info_char_item'
      ),
      value: RevisionType.Character
    }
  ];

const onNext = () => {
  emit('onNext');
};

const onClickFullScreenOn = async () => {
  const content = await showDialog<string>({
    component: shallowRef(RatingSurveyDialogTextareaFullScreen),
    props: {
      title: t(
        'studio.prj_prod_mngmt.rating_build_review.self_review.report_change.changed_info_detail'
      ),
      content: revisionContent.value,
      wordCountLimit: WORD_COUNT_LIMIT,
      disabled: props.mode === RatingPageMode.DETAIL
    },
    severity: 'info'
  });

  setRevisionContent(content);
};

const translateList = (contentInfos: string[]) => {
  const list = contentInfos.map((contentInfo: string) => {
    return t(contentInfo);
  });

  return list.join(', ');
};

const init = () => {
  if (props.mode === RatingPageMode.DETAIL) {
    setPreviousSurveyAnswer(false);
  } else {
    setPreviousSurveyAnswer(true);
  }
};

init();
</script>
