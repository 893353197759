<!-- 28-1-6-A -->
<template>
  <s-dialog to="rating-survey-dialog-attach-full-screen" :open="true">
    <s-dialog-panel :id="uuid" class="!w-full !h-full !max-h-[initial] !rounded-none">
      <div
        class="flex h-full w-full flex-col bg-background-variant-2 p-32 dark:bg-surface-variant-1"
      >
        <div class="mb-16 flex shrink-0 items-center gap-16">
          <h2 class="text-xl font-bold leading-lg text-on-surface-elevation-1">
            {{ title }}
          </h2>
          <div class="ml-auto shrink-0">
            <s-button
              size="xs"
              variant="outline"
              icon="ic-v2-media-av-fullscreen-exit-fill"
              @click="handleCloseDialog"
            >
              {{ $t('studio.prj_prod_mngmt.prod_home.create_term.btn_exit_fullscr') }}
            </s-button>
          </div>
        </div>
        <st-textarea
          v-model="textContent"
          :placeholder="''"
          :maxLength="wordCountLimit.toString()"
          containerClass="mt-16 [&>.stds-textarea-container]:h-240 flex-1 min-h-0"
          :disabled="disabled"
        />
      </div>
    </s-dialog-panel>
  </s-dialog>

  <s-portal-target name="rating-survey-dialog-attach-full-screen" />
</template>
<script setup lang="ts">
import { useHead } from '@unhead/vue';
import { useForm } from 'vee-validate';
import { onMounted, ref, toRefs } from 'vue';

import StTextarea from '@/components/validation/textarea.vue';
import { generateUUID } from '@/utils/uuid.util';

useHead({
  bodyAttrs: {
    class: 'overflow-hidden'
  }
});

const uuid: string = `stds-dialog-${generateUUID()}`;

const props = defineProps<{
  title: string;
  content: string;
  wordCountLimit: number;
  disabled?: boolean;
}>();

const emits = defineEmits<{
  close: [v: string];
}>();

const { content } = toRefs(props);

const { setValues } = useForm({
  initialValues: {
    textContent: ''
  }
});

const textContent = ref<string>(content.value);

const handleCloseDialog = () => {
  emits('close', textContent.value);
};

onMounted(async () => {
  setValues({ textContent: content.value });
});
</script>
