<!-- RatingSurveyHeader -->
<template>
  <div class="grid h-84 shrink-0 grid-cols-3 gap-20 bg-background-variant-2 px-20">
    <div class="flex items-center">
      <button type="button" class="shrink-0" @click="onClose">
        <s-icon
          size="4xl"
          icon="ic-v2-control-close-line"
          class="align-top text-on-surface-elevation-1"
          srOnlyText="Close"
        />
      </button>
      <h2 class="ml-12 text-2xl font-bold leading-xl">{{ headerTitle }}</h2>
    </div>
    <div class="flex items-center justify-center">
      <div class="flex rounded-full bg-disabled-variant-3 p-2">
        <button
          v-if="type === SurveyDialogType.OBJECTION"
          class="inline-flex h-40 min-w-220 px-20 items-center justify-center gap-[.6rem] rounded-full"
          :class="{ 'bg-neutral-variant-1 ': currentActiveTab === RatingSurveyTab.OBJECTION }"
          @click="onClickDetail(RatingSurveyTab.OBJECTION)"
        >
          <span
            class="text-xl font-medium leading-lg"
            :class="
              currentActiveTab === RatingSurveyTab.OBJECTION
                ? ' text-surface-elevation-1'
                : 'text-on-surface-elevation-3'
            "
          >
            {{ $t('studio.prj_prod_mngmt.rating_build_review.self_review.appeal_detail_input') }}
          </span>
        </button>
        <button
          v-if="type === SurveyDialogType.REVISION"
          class="inline-flex h-40 min-w-220 px-20 items-center justify-center gap-[.6rem] rounded-full"
          :class="{ 'bg-neutral-variant-1 ': currentActiveTab === RatingSurveyTab.REVISION }"
          @click="onClickDetail(RatingSurveyTab.REVISION)"
        >
          <span
            class="text-xl font-medium leading-lg"
            :class="
              currentActiveTab === RatingSurveyTab.REVISION
                ? ' text-surface-elevation-1'
                : 'text-on-surface-elevation-3'
            "
          >
            {{ $t('studio.prj_prod_mngmt.rating_build_review.self_review.appeal_detail_input') }}
          </span>
        </button>
        <button
          v-if="!hideSurveyTab"
          class="inline-flex h-40 min-w-220 px-20 items-center justify-center gap-[.6rem] rounded-full"
          :class="{ 'bg-neutral-variant-1 ': currentActiveTab === RatingSurveyTab.SURVEY }"
          @click="onClickDetail(RatingSurveyTab.SURVEY)"
        >
          <span
            class="text-xl font-medium leading-lg"
            :class="
              currentActiveTab === RatingSurveyTab.SURVEY
                ? ' text-surface-elevation-1'
                : 'text-on-surface-elevation-3'
            "
          >
            {{ $t('studio.prj_prod_mngmt.rating_build_review.self_review.appeal_qtn') }}
          </span>
          <rating-survey-progress-state-icon
            v-if="currentActiveTab === RatingSurveyTab.SURVEY"
            :status="status"
          />
        </button>
        <button
          class="inline-flex h-40 min-w-220 px-20 items-center justify-center gap-[.6rem] rounded-full"
          :class="{ 'bg-neutral-variant-1 ': currentActiveTab === RatingSurveyTab.ATTACH }"
          @click="onClickDetail(RatingSurveyTab.ATTACH)"
        >
          <span
            class="text-xl font-medium leading-lg"
            :class="
              currentActiveTab === RatingSurveyTab.ATTACH
                ? ' text-surface-elevation-1'
                : 'text-on-surface-elevation-3'
            "
          >
            {{ $t('studio.prj_prod_mngmt.rating_build_review.self_review.appeal_attch_doc') }}
          </span>
        </button>
      </div>
    </div>
    <div class="flex items-center justify-end gap-8">
      <s-button
        v-if="!hideSaveDraftButton"
        variant="primary"
        size="md"
        class="!min-w-100"
        :isDisabled="mode === RatingPageMode.DETAIL"
        @click="onClickSaveDraft"
      >
        {{ $t('studio.common.popup_case_save_btn') }}
      </s-button>
      <s-button
        variant="primary"
        :isDisabled="mode === RatingPageMode.DETAIL"
        size="md"
        class="!min-w-100"
        @click="onClickRequestReview"
      >
        {{ $t('studio.prj_prod_mngmt.rating_build_review.self_review.appeal_req_btn') }}
      </s-button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { toRefs } from 'vue';

import RatingSurveyProgressStateIcon from '@/components/rating/rating-survey-progress-state-icon.vue';
import {
  RatingPageMode,
  RatingSurveyStatus,
  RatingSurveyTab,
  SurveyDialogType
} from '@/enums/rating.enum';

const props = withDefaults(
  defineProps<{
    headerTitle: string;
    currentActiveTab: RatingSurveyTab;
    status: RatingSurveyStatus;
    type: SurveyDialogType;
    hideSurveyTab: boolean;
    mode?: RatingPageMode;
    hideSaveDraftButton: boolean;
  }>(),
  { mode: RatingPageMode.EDIT, hideSurveyTab: false, hideSaveDraftButton: false }
);

const emit = defineEmits<{
  changeTab: [pageKey: RatingSurveyTab];
  close: [v: boolean];
  onSaveDraft: [];
  onRequestReview: [];
}>();

const { headerTitle, currentActiveTab, status } = toRefs(props);

const onClickSaveDraft = () => {
  emit('onSaveDraft');
};

const onClickRequestReview = async () => {
  emit('onRequestReview');
};

const onClose = () => {
  emit('close', true);
};

const onClickDetail = (tab: RatingSurveyTab) => {
  emit('changeTab', tab);
};
</script>
