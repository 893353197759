<!-- 28-1-4-1 -->
<template>
  <st-box>
    <div class="flex gap-8 mb-24">
      <h3 class="text-5xl font-bold leading-4xl text-on-surface-elevation-2">
        {{ `${no} ${category}` }}
      </h3>
      <span v-if="categoryContentsLength > 1" class="ml-auto text-2xs leading-xs text-placeholder">
        <em class="font-medium text-on-surface-elevation-2">{{ activeQuestionIndex + 1 }}</em>/{{ categoryContentsLength }}
      </span>
    </div>

    <h4 class="text-2xl font-bold leading-xl text-brand-primary">
      {{ `${surveyContent.no} ${surveyContent.title}` }}
    </h4>
    <p class="mt-4 text-sm leading-md text-on-surface-elevation-4 whitespace-pre-line">
      {{ surveyContent.description }}
    </p>
    <template v-for="question in surveyContent.contents" :key="question.id">
      <div
        v-if="!question.hide"
        :class="{
          'mt-12 px-24 py-20 bg-[var(--stds-glob-color-gray40)] rounded-3xl':
            question.no.split('.').length === 5
        }"
      >
        <p
          class="text-md font-bold leading-lg text-on-surface-elevation-2"
          :class="{ 'mt-24': question.no.split('.').length !== 5 }"
        >
          {{ `${question.no} ${question.question}` }}
        </p>
        <p
          v-if="question.description"
          class="mt-4 text-sm leading-md text-on-surface-elevation-4 whitespace-pre-line"
        >
          {{ question.description }}
        </p>
        <p
          v-if="
            isSubmittedReview &&
              ((Array.isArray(question.answers) && question.answers.length === 0) ||
                !question.answers) &&
              question.active
          "
          class="mt-4 flex gap-2 text-xs leading-xs text-error"
        >
          <s-icon size="xl" icon="ic-v2-state-warning-circle-fill" class="mt-[.1rem]" />
          {{ $t('studio.prj_prod_mngmt.rating_build_review.self_review_req.qtn_required_msg') }}
        </p>
        <questionnaire-item
          :question="question"
          :disabled="disabled"
          @onClickChoice="onClickChoice"
        />
      </div>
    </template>
  </st-box>
  <div class="flex justify-center gap-16 mt-40">
    <s-button
      variant="white"
      size="lg"
      class="!min-w-96"
      :isDisabled="surveyContent && surveyContent.no === firstQuestionNo"
      @click="onPrevious"
    >
      {{ $t('studio.prj_prod_mngmt.rating_build_review.self_review.qtn_back_btn') }}
    </s-button>
    <s-button
      v-if="
        surveyStatus === RatingSurveyStatus.COMPLETE &&
          activeSurveyCategoryIndex + 1 === surveyQuestionnaireLength
      "
      variant="primary"
      size="lg"
      class="!min-w-[12.6rem]"
      @click="onClickChangeTab(RatingSurveyTab.ATTACH)"
    >
      {{ $t('studio.prj_prod_mngmt.rating_build_review.self_review.appeal_attch_doc') }}
    </s-button>
    <s-button
      v-else
      variant="secondary"
      size="lg"
      class="!min-w-[12.6rem]"
      :isDisabled="surveyContent && surveyContent.no === lastQuestionNo"
      @click="onNext"
    >
      {{ $t('studio.prj_prod_mngmt.rating_build_review.self_review.qtn_next_btn') }}
    </s-button>
  </div>
</template>
<script setup lang="ts">
import { useHead } from '@unhead/vue';

import StBox from '@/components/common/st-box.vue';
import QuestionnaireItem from '@/components/rating/questionnaire/questionnaire-item.vue';
import { RatingSurveyStatus, RatingSurveyTab } from '@/enums/rating.enum';
import type {
  DraftQuestionnaireReviewQuestionnaireContent,
  DraftQuestionnaireReviewQuestionnaireQuestion
} from '@/types/rating/rating.response.type';

defineProps<{
  surveyContent: DraftQuestionnaireReviewQuestionnaireContent;
  no: string;
  category: string;
  categoryContentsLength: number;
  activeQuestionIndex: number;
  disabled: boolean;
  surveyStatus: RatingSurveyStatus;
  activeSurveyCategoryIndex: number;
  surveyQuestionnaireLength: number;
  firstQuestionNo: string;
  lastQuestionNo: string;
  isSubmittedReview: boolean;
}>();

const emit = defineEmits<{
  onClickChoice: [
    question: DraftQuestionnaireReviewQuestionnaireQuestion,
    nextId: string,
    choiceId: number,
    value: Event
  ];
  onPrevious: [];
  onNext: [];
  onClickChangeTab: [tab: RatingSurveyTab];
}>();

useHead({
  bodyAttrs: {
    class: 'overflow-hidden'
  }
});

const onClickChoice = (
  question: DraftQuestionnaireReviewQuestionnaireQuestion,
  nextId: string,
  choiceId: number,
  value: Event
) => {
  emit('onClickChoice', question, nextId, choiceId, value);
};

const onPrevious = () => {
  emit('onPrevious');
};

const onNext = () => {
  emit('onNext');
};

const onClickChangeTab = (tab: RatingSurveyTab) => {
  emit('onClickChangeTab', tab);
};
</script>
