<!-- 28-2-3 -->
<template>
  <st-box>
    <st-form-title
      :formTitle="$t('studio.prj_prod_mngmt.rating_build_review.self_review.appeal_req_rating')"
      required
    />
    <div class="relative mt-8">
      <dropdown
        size="lg"
        variant="line"
        :distance="0"
        :offset="[0, 0]"
        :closeOnClick="true"
        :interactive="true"
        appendTo="parent"
        placement="bottom-start"
        class="w-full"
        :options="adjustRatingList"
        :rules="{
          required: $t(
            'studio.prj_prod_mngmt.rating_build_review.self_review.appeal_req_rating_val_msg'
          )
        }"
        name="objection.adjustRating"
        :placeholder="
          $t('studio.prj_prod_mngmt.rating_build_review.self_review.appeal_req_rating_place_holder')
        "
        :disabled="mode === RatingPageMode.DETAIL"
      />
    </div>
    <div class="mt-12">
      <checkbox
        v-if="mode === RatingPageMode.EDIT"
        id="chk"
        size="sm"
        align="middle"
        name="objection.usePreviousSurveyAnswer"
      >
        <span class="leading-lg text-on-surface-elevation-1">
          {{
            $t(
              'studio.prj_prod_mngmt.rating_build_review.self_review.appeal_use_existed_info_chkbox'
            )
          }}
        </span>
      </checkbox>
    </div>

    <st-form-title
      :formTitle="$t('studio.prj_prod_mngmt.rating_build_review.self_review.appeal_select_content')"
      class="mt-24"
    />
    <div class="flex flex-wrap gap-8 py-4">
      <checkbox-buttons
        name="objection.contentInfos"
        :options="gameContentsList"
        :disabled="mode === RatingPageMode.DETAIL"
      />
      <!-- @update:modelValue="(key) => (checkGameContentsList = key)" -->
    </div>

    <st-form-title
      :formTitle="$t('studio.prj_prod_mngmt.rating_build_review.self_review.appeal_reason_input')"
      required
      class="mt-24"
    >
      <div class="shrink-0 ml-auto">
        <!-- @TODO :: 전체 화면 에디터 로직 일괄 수정 예정 -->
        <s-button
          size="xs"
          variant="outline"
          icon="ic-v2-media-av-fullscreen-fill"
          :isDisabled="isCodeView"
          @click="onClickReasonFullScreenOn"
        >
          {{ $t('studio.common.full_scr_btn') }}
        </s-button>
      </div>
    </st-form-title>
    <!-- 에디터 -->
    <text-editor
      v-model="reasonRef"
      name="objection.reason"
      :toolbarButtons="TOOLBAR_BUTTONS_FROALA_ONLY_WRITING"
      :config="editorConfig"
      :charCounterMax="WORD_COUNT_LIMIT"
      class="h-[52rem] mt-16"
      :rules="{
        required: $t('studio.prj_prod_mngmt.rating_build_review.self_review.appeal_reason_val_msg'),
        max_char_count: {
          length: WORD_COUNT_LIMIT,
          charCount: reasonCharCount,
          message: $t('studio.common.def_key.exceed', {
            length: formatNumberMultipleWithCommas(WORD_COUNT_LIMIT)
          })
        }
      }"
      :disabled="mode === RatingPageMode.DETAIL"
      @onClickBtnHtmlCodeBlock="isCodeView = $event"
      @update:modelValue="updateReason"
      @wordCount="onReasonCharCount"
    />
  </st-box>
  <div class="flex justify-center gap-16 mt-40">
    <s-button variant="secondary" size="lg" class="!min-w-160" @click="onNext">
      {{ $t('studio.prj_prod_mngmt.rating_build_review.self_review.appeal_next_btn') }}
    </s-button>
  </div>
</template>
<script setup lang="ts">
import { useHead } from '@unhead/vue';
import { useSetFieldValue } from 'vee-validate';
import { computed, ref, shallowRef, toRefs, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import StBox from '@/components/common/st-box.vue';
import StFormTitle from '@/components/common/st-form-title.vue';
import RatingSurveyDialogTextEditorFullScreen from '@/components/rating/dialog/text-editor-dialog.vue';
import Checkbox from '@/components/validation/checkbox.vue';
import CheckboxButtons from '@/components/validation/checkbox-buttons.vue';
import Dropdown from '@/components/validation/dropdown.vue';
import TextEditor from '@/components/validation/text-editor.vue';
import { showDialog } from '@/composables/useDialog';
import { TOOLBAR_BUTTONS_FROALA_ONLY_WRITING } from '@/constants/froala.const';
import { RatingAge, RatingContentCategory, RatingPageMode } from '@/enums/rating.enum';
import type { RequestRating } from '@/types/rating/rating.response.type';
import { formatNumberMultipleWithCommas } from '@/utils/currency.util';
import { initFullScreenEditor } from '@/utils/froala.util';

const WORD_COUNT_LIMIT = 10000;
const props = withDefaults(
  defineProps<{
    currentRating?: RequestRating;
    reason: string;
    mode?: RatingPageMode;
  }>(),
  {
    currentRating: undefined,
    reason: '',
    mode: RatingPageMode.EDIT
  }
);

const emit = defineEmits<{
  onNext: [];
}>();

const { currentRating, reason } = toRefs(props);

const { t } = useI18n();
const reasonRef = ref<string>(reason.value);
const setReasonValue = useSetFieldValue('objection.reason');

const editorConfig = {
  attribution: false,
  charCounterCount: true,
  wordCounterCount: false,
  placeholderText: '',
  events: {
    initialized: function() {
      const editor = this as any;
      initFullScreenEditor(editor);
    }
  }
};

const gameContentsList = ref<Array<{ label: string; value: string }>>([
  {
    value: RatingContentCategory.VIOLENT,
    label: t('studio.prj_prod_mngmt.rating_build_review.self_review.appeal_content_violence')
  },
  {
    value: RatingContentCategory.OBSCENE,
    label: t('studio.prj_prod_mngmt.rating_build_review.self_review.appeal_content_sexuality')
  },
  {
    value: RatingContentCategory.GAMBLING,
    label: t('studio.prj_prod_mngmt.rating_build_review.self_review.appeal_content_gambling')
  },
  {
    value: RatingContentCategory.HORROR,
    label: t('studio.prj_prod_mngmt.rating_build_review.self_review.appeal_content_horror')
  },
  {
    value: RatingContentCategory.VILIFICATION,
    label: t('studio.prj_prod_mngmt.rating_build_review.self_review.appeal_content_lang')
  },
  {
    value: RatingContentCategory.MEDICATION,
    label: t('studio.prj_prod_mngmt.rating_build_review.self_review.appeal_content_drug')
  },
  {
    value: RatingContentCategory.CRIME,
    label: t('studio.prj_prod_mngmt.rating_build_review.self_review.appeal_content_crime')
  }
]);

const isCodeView = ref<boolean>(false);
const reasonCharCount = ref<number>(0);

useHead({
  bodyAttrs: {
    class: 'overflow-hidden'
  }
});

const onReasonCharCount = (count: number) => {
  reasonCharCount.value = count;
};

const updateReason = (value: string) => {
  setReasonValue(value);
};

const onClickReasonFullScreenOn = async () => {
  const content = await showDialog<string>({
    component: shallowRef(RatingSurveyDialogTextEditorFullScreen),
    props: {
      title: t('studio.prj_prod_mngmt.rating_build_review.self_review.appeal_reason_input'),
      content: reasonRef.value,
      wordCountLimit: WORD_COUNT_LIMIT,
      toolbarButtons: TOOLBAR_BUTTONS_FROALA_ONLY_WRITING,
      requiredMessage: t(
        'studio.prj_prod_mngmt.rating_build_review.self_review.appeal_reason_val_msg'
      ),
      disabled: props.mode === RatingPageMode.DETAIL
    },
    severity: 'info'
  });

  reasonRef.value = content;
  setReasonValue(content);
};

const onNext = () => {
  emit('onNext');
};

const adjustRatingList = computed(() => {
  let currentAgeRating = t(
    'studio.prj_prod_mngmt.rating_build_review.self_review.post_review_guide1_rating_all'
  );
  switch (currentRating.value?.ageRating) {
    case RatingAge.Age12:
      currentAgeRating = t(
        'studio.prj_prod_mngmt.rating_build_review.self_review.post_review_guide1_rating_by_age',
        { rating: RatingAge.Age12 }
      );
      break;
    case RatingAge.Age15:
      currentAgeRating = t(
        'studio.prj_prod_mngmt.rating_build_review.self_review.post_review_guide1_rating_by_age',
        { rating: RatingAge.Age15 }
      );
      break;
  }
  return [
    {
      value: RatingAge.AllAges,
      label: `${currentAgeRating}→${t(
        'studio.prj_prod_mngmt.rating_build_review.self_review.post_review_guide1_rating_all'
      )}`
    },
    {
      value: RatingAge.Age12,
      label: `${currentAgeRating}→${t(
        'studio.prj_prod_mngmt.rating_build_review.self_review.post_review_guide1_rating_by_age',
        { rating: RatingAge.Age12 }
      )}`
    },
    {
      value: RatingAge.Age15,
      label: `${currentAgeRating}→${t(
        'studio.prj_prod_mngmt.rating_build_review.self_review.post_review_guide1_rating_by_age',
        { rating: RatingAge.Age15 }
      )}`
    }
  ];
});

watch(reason, (value: string) => {
  reasonRef.value = value;
});
</script>
